import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ArtistPage from 'commons/ArtistPage';
import API from 'configs/API';
import { axiosGet } from 'utils/apis/axios';

import useStyles from './styles';

const ArtistProfile = () => {
  const classes = useStyles();
  const [userProfile, setUserProfile] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const option = {
      params: {
        user_uuid: id,
      },
    };

    const getProfile = async () => {
      const { success, data } = await axiosGet(API.AUTH.PROFILE_INFO, option);
      if (success) {
        setUserProfile(data);
      }
    };

    getProfile();
  }, [id]);

  return (
    <Box className={classes.wrapper}>
      <Container>
        <ArtistPage
          userProfile={userProfile}
          wrapStyle={classes.wrapContent}
        />
      </Container>
    </Box>
  );
};

export default ArtistProfile;
