import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import ExhibitionItem from 'commons/Exhibition/ExhibitionItem';
import PaginationCustom from 'commons/PaginationCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';
import { useQueryParameters } from 'utils/utils';

import TabArtworkHeader from '../TabArtworkHeader';
import useStyles from './styles';

const GalleryExhibition = ({ isOwner }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const query = useQueryParameters();
  const searchValue = query.get('search');

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const objectParamsFromSearchParams = Object.fromEntries(searchParams);
  const params = {
    ...objectParamsFromSearchParams,
    user_uuid: id,
  };

  const { data, isPending } = useQuery({
    queryKey: [API.ARTWORK.EXHIBITION, params],
  });

  const { page, totalPage, onPageChange } = usePagination(data?.count, data?.page_size);

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const listExhibition = data?.results;

  if (isPending) {
    return;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <TabArtworkHeader
        placeholder={t('search_by_exhibition_name')}
        isOwner={isOwner}
      />

      <Box sx={{ pt: 3 }}>
        <Grid
          container
          spacing={5}
        >
          {listExhibition?.map((exhibition, index) => (
            <Grid
              key={index}
              item
              xs={12}
              lg={3}
            >
              <ExhibitionItem
                exhibition={exhibition}
                isOwner={isOwner}
              />
            </Grid>
          ))}
        </Grid>

        {searchValue && data?.count === 0 && (
          <div className={classes.wrapTextNoResult}>
            <Text>{t('no_results_that_match_your_search')}</Text>
            <Text>{t('please_try_adjust_your_keywords')}</Text>
          </div>
        )}
      </Box>

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default GalleryExhibition;
