import { Stack } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const ExhibitionInfoRow = ({ label, content, sx, contentBold }) => {
  const classes = useStyles();

  return (
    <Stack
      direction="row"
      sx={sx}
      spacing={2}
    >
      <Text className={classes.textWidth}>{label}:</Text>
      <Text fontWeightMedium={contentBold}>{content || '----'}</Text>
    </Stack>
  );
};

export default ExhibitionInfoRow;
