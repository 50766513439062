import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pagination: {
    '&.MuiPagination-root': {
      marginTop: 40,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  wrapTextNoResult: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
    height: 'auto',
    textAlign: 'center',
  },
}));

export default useStyles;
