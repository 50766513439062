import React from 'react';

import GridArtworkInfo from './components/GridArtworkInfo';
import useStyles from './style';

const CertificateArtworkInfo = ({ artwork, certificate }) => {
  const classes = useStyles();

  const timestamp = new Date().getTime();
  const baseImage = artwork?.images?.[0] ?? artwork?.image ?? null;
  const imageWithTimestamp = baseImage
    ? baseImage.includes('?')
      ? `${baseImage}&v=${timestamp}`
      : `${baseImage}?v=${timestamp}`
    : null;

  return (
    <>
      <img
        src={imageWithTimestamp}
        className={classes.image}
        crossOrigin="anonymous"
      />

      <GridArtworkInfo
        artwork={artwork}
        certificate={certificate}
      />
    </>
  );
};

export default CertificateArtworkInfo;
