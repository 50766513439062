import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapFormInput: {
    marginTop: 0,
  },
  wrapBox: {
    backgroundColor: Colors.Grey1,
    width: '100%',
    padding: 24,
    border: `1px solid ${Colors.Grey3}`,
    marginBottom: 16,
  },
  wrapInput: {
    width: '100%',
  },
  labelText: {
    fontSize: '14px',
  },
}));

export default useStyles;
