import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'sticky',
    top: 0,
  },
  menuItemWrap: {
    width: '100%',
    paddingBottom: 8,
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
}));

export default useStyles;
