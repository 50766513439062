import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import OurMission1 from 'images/our_mission_1.png';
import OurMission2 from 'images/our_mission_2.png';
import OurMission3 from 'images/our_mission_3.png';

import useStyles from './styles';

const OurMission = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      className={classes.gridOurMission}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <div className={classes.wrapImageOurMission}>
          <img
            className={classes.ourMission2}
            src={OurMission2}
          />
          <div className={classes.wrapSubImageOurMission}>
            <img src={OurMission3} />
            <img src={OurMission1} />
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <Text className={classes.textWhatGladiusArt}>{t('what_is_gladius_art')}</Text>
        <Text className={classes.textEmpoweringArtist}>{t('empowering_artists')}</Text>
        <Text className={classes.textAtGladiusArt}>{t('gladiusArt_is_spearheading')}</Text>

        <Text className={classes.textBreakTraditional}>
          {t('breaking_traditional_constraints')}
        </Text>
        <Text className={classes.textAtGladiusArt}>
          {t('we_provide_advanced_art_data_storage_tools')}
        </Text>
      </Grid>
    </Grid>
  );
};

export default OurMission;
