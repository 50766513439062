import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Divider, Grid } from '@mui/material';
import Icon from '@mui/material/Icon';
import React from 'react';

import ImageCustom from 'commons/ImageCustom';
import Text from 'commons/Text';
import { IMAGE_TYPE, TESTID } from 'configs/Constant';
import { ReactComponent as IconPdf } from 'images/icon_pdf.svg';

import useStyles from './styles';

const AwardListGrid = ({ listAward, onClickOpenDialogDelete, onClickOpenDialogEdit }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      rowSpacing={1}
    >
      {listAward.map((item, index) => (
        <Grid
          key={index}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.listImageItemAward}>
            <div className={classes.wrapYearAward}>
              {item?.is_public ? (
                <VisibilityOutlinedIcon className={classes.iconVisibility} />
              ) : (
                <VisibilityOffOutlinedIcon className={classes.iconVisibility} />
              )}
              <Text className={classes.textYearAward}>{item?.year ? item.year : 'N/A'}</Text>
            </div>
            <div className={classes.wrapDescriptionAndIcon}>
              <div className={classes.wrapDescriptionAndImage}>
                <Text>{item?.description}</Text>
                <div className={classes.wrapImagItemAward}>
                  {item.list_image.map((objectImage, index) => {
                    const typeFile = objectImage?.key_images?.split('.').pop();
                    return (
                      <div key={index}>
                        {typeFile === IMAGE_TYPE.PDF ? (
                          <IconPdf className={classes.iconPdf} />
                        ) : (
                          <ImageCustom
                            src={objectImage?.image}
                            imageStyle={classes.imagItemAward}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                <Icon
                  onClick={() => onClickOpenDialogEdit(item)}
                  className={classes.editIcon}
                  testid={TESTID.EDIT_ICON_AWARD_LIST}
                >
                  <EditOutlinedIcon />
                </Icon>
                <Icon
                  onClick={() => onClickOpenDialogDelete(item)}
                  testid={TESTID.CLOSE_ICON_AWARD_LIST}
                >
                  <CloseIcon />
                </Icon>
              </div>
            </div>
          </div>
          <Divider className={classes.dividerStyle} />
        </Grid>
      ))}
    </Grid>
  );
};

export default AwardListGrid;
