import { Box } from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { useRef } from 'react';

import ArtistProfileColLeft from './components/ArtistProfileColLeft';
import ArtistProfileColRight from './components/ArtistProfileColRight';
import useStyles from './styles';

const ArtistPage = ({ userProfile, wrapStyle }) => {
  const classes = useStyles();
  const profileContent = useRef('');

  const downloadPDF = async () => {
    const content = profileContent.current;

    try {
      const canvas = await html2canvas(content, {
        useCORS: true,
        scale: 2,
      });

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const paddingLeft = 10;
      const paddingRight = 10;

      const contentWidth = pageWidth - paddingLeft - paddingRight;
      const scaleFactor = contentWidth / canvas.width; // Điều chỉnh tỷ lệ ảnh dựa trên chiều rộng với padding
      const imgScaledWidth = contentWidth;
      const imgScaledHeight = canvas.height * scaleFactor;

      let currentYPosition = 0;

      const addImageToPDF = (yPosition) => {
        pdf.addImage(imgData, 'PNG', paddingLeft, yPosition, imgScaledWidth, imgScaledHeight);
      };

      addImageToPDF(currentYPosition);
      currentYPosition += imgScaledHeight;

      while (currentYPosition > pageHeight) {
        pdf.addPage(); // Thêm trang mới

        const remainingHeight = currentYPosition - pageHeight;
        addImageToPDF(-remainingHeight); // Thêm nội dung vào vị trí đúng

        currentYPosition -= pageHeight;
      }

      pdf.save('profile.pdf');
    } catch (error) {
      console.error('Lỗi khi tạo PDF:', error);
    }
  };

  return (
    <Box
      className={wrapStyle}
      ref={profileContent}
    >
      <Box>
        <Box
          sx={{ pt: 3 }}
          className={classes.wrapCol}
        >
          <ArtistProfileColLeft
            userProfile={userProfile}
            downloadPDF={downloadPDF}
          />
          <ArtistProfileColRight userProfile={userProfile} />
        </Box>
      </Box>

      {/* <SpecialWorks
        title={t('special_works')}
        userUuid={artistUuid || id}
      /> */}
    </Box>
  );
};

export default ArtistPage;
