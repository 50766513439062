import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import API from 'configs/API';
import Routes from 'utils/Route';

const useCollection = (id, options = {}) => {
  const navigate = useNavigate();

  const {
    data: collection,
    error,
    isPending,
    ...query
  } = useQuery({
    queryKey: [API.ARTWORK.COLLECTION_DETAIL_FOR_EDIT(id)],
    ...options,
  });

  useEffect(() => {
    if (error && error?.error?.response?.status === 404) {
      navigate(Routes.PageNotFound);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (!isPending && !collection?.is_owner) {
      navigate(Routes.PageNotFound);
    }
  }, [collection, isPending, navigate]);

  return { collection, isPending, ...query };
};

export default useCollection;
