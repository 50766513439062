import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ExhibitionGroupAction from 'commons/Exhibition/ExhibitionGroupActions';
import Text from 'commons/Text';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import GridLayout from 'layouts/GridLayout';
import useUpdateExhibition from 'pages/EditExhibition/hooks/useUpdateExhibition';
import { groupSchema } from 'utils/Validation';

import ExhibitionGroupForm from './components/ExhibitionGroupForm';

const ExhibitionGroupStep = ({ step, setStep, exhibition, informationFormState }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);
  const { mutate: updateExhibition } = useUpdateExhibition(exhibition?.id, user?.uuid);
  const { uploadImage } = useUploadImageS3();

  const [groupIndex, setGroupIndex] = useState(0);

  const { control, register, watch, setValue, formState, handleSubmit } = useForm({
    values: {
      groups: exhibition?.groups,
    },
    mode: 'onChange',
    resolver: yupResolver(groupSchema(t)),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'groups',
  });

  const handleAddGroup = () => {
    append({
      title: `Group ${fields?.length + 1}`,
      description: '',
      artworks: [],
    });
    setGroupIndex((prev) => prev + 1);
  };

  const handleSelectGroup = (index) => {
    setGroupIndex(index);
  };

  const handleDeleteGroup = () => {
    remove(groupIndex);
    setGroupIndex(0);
  };

  const handleSubmitForm = async (data) => {
    const informationData = informationFormState.getValues();

    const formData = {
      title: informationData?.exhibitionTitle,
      date_start: moment(informationData.dateStart).format('YYYY-MM-DD'),
      date_end: moment(informationData.dateEnd).format('YYYY-MM-DD'),
      address: informationData?.address,
      preface: informationData?.preface,
      event_type: informationData?.typeOfEvent?.key,
      organizer_name: informationData?.organizerName,
      is_public: true,
      is_draft: false,
      groups: data?.groups,
    };

    const { coverUploadFile } = informationData;
    if (
      coverUploadFile &&
      Object.keys(coverUploadFile).length > 0 &&
      (await uploadImage(coverUploadFile)).success
    ) {
      formData.cover_image = coverUploadFile.imageKey;
    }
    updateExhibition(formData);
  };

  const handleBackStep = () => {
    setStep((prev) => prev - 1);
  };

  return (
    <>
      <Stack
        direction={'row'}
        sx={{ mb: 2, alignItems: 'center' }}
        onClick={handleBackStep}
      >
        <ArrowBackIcon />
        <Text
          ml={8}
          type="sm"
        >
          {t('back_step')}
        </Text>
      </Stack>
      <Text
        type="2xl"
        fontWeightBold
        variant="h1"
      >
        {t('exhibition_groupings')}
      </Text>

      <Text
        mt={10}
        mb={24}
      >
        {t('text_fill_exhibition_group')}
      </Text>

      <GridLayout spacing={2}>
        <ExhibitionGroupForm
          fields={fields}
          register={register}
          watch={watch}
          groupIndex={groupIndex}
          onDeleteGroup={handleDeleteGroup}
          setValue={setValue}
          formState={formState}
          key={groupIndex}
        />
        <ExhibitionGroupAction
          fields={fields}
          step={step}
          onAddGroup={handleAddGroup}
          onSelectGroup={handleSelectGroup}
          onNextStep={handleSubmit(handleSubmitForm)}
          watch={watch}
          user={user}
        />
      </GridLayout>
    </>
  );
};

export default ExhibitionGroupStep;
