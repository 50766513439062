import React from 'react';

import SearchInputIcon from 'commons/Search/SearchInputIcon';
import { useSearch } from 'hooks/useSearch';

import SortArtist from '../SortArtist';
import useStyles from './styles';

const HeaderListArtist = ({ placeholder }) => {
  const classes = useStyles();

  const { search, handleSearch } = useSearch();

  return (
    <div className={classes.wrapHearder}>
      <SearchInputIcon
        value={search}
        onChange={handleSearch}
        placeholder={placeholder}
        wrapStyle={classes.wrapSearch}
      />
      <SortArtist />
    </div>
  );
};

export default HeaderListArtist;
