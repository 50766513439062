import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import DialogCustom from 'commons/DialogCustom';
import GroupThreeButtonProgress from 'commons/GroupThreeButtonProgress';
import Text from 'commons/Text';
import useRole from 'hooks/useRole';
import Routes from 'utils/Route';

import CreateGroupAction from '../CreateGroupAction';
import useStyles from './styles';

const ExhibitionGroupAction = ({ watch, onAddGroup, onSelectGroup, onNextStep, step, user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { isCollectorOrGalleryOwner } = useRole();

  const [isShowDialog, setIsShowDialog] = useState(false);

  const handleShowDialogCancel = () => {
    setIsShowDialog(true);
  };

  const handleCancleDialog = (e) => {
    e.preventDefault();
    setIsShowDialog(false);
  };
  const handleSubmitDialog = (e) => {
    e.preventDefault();
    const searchParams = createSearchParams({ tab: '5' }).toString();
    navigate({
      pathname: Routes.GalleryDetail(user?.uuid),
      search: searchParams,
    });
    setIsShowDialog(false);
  };

  const artistNames = [
    ...new Set(
      watch('groups')
        ?.flatMap((group) =>
          group?.selectedArtworks?.map((artwork) => {
            if (artwork?.artist_tag_request) {
              return artwork?.artist_tag_request?.request_to?.name;
            }
            return artwork?.artist_artwork?.artist_name;
          }),
        )
        .filter(Boolean),
    ),
  ].join(', ');

  return (
    <>
      <CreateGroupAction
        watch={watch}
        onAddGroup={onAddGroup}
        onSelectGroup={onSelectGroup}
      />

      {isCollectorOrGalleryOwner && (
        <Box className={classes.boxArtistWrapper}>
          <Text
            fontWeightBold
            type="lg"
          >
            {t('related_artists')}
          </Text>

          <Box sx={{ mt: 2 }}>{artistNames}</Box>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <GroupThreeButtonProgress
          step={step}
          textButtonOne={t('next')}
          textButtonTwo={t('cancel')}
          textButtonThree={t('save_as_draft')}
          onClickButtonOne={onNextStep}
          onClickButtonTwo={handleShowDialogCancel}
        />
      </Box>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('cancel')}
        textCancel={t('cancel')}
        textSubmit={t('ok')}
        onCancel={handleCancleDialog}
        onSubmit={handleSubmitDialog}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
        cancelButtonStyle={clsx(classes.buttonDialog)}
      >
        {t('are_you_sure_exit_certificate')}
      </DialogCustom>
    </>
  );
};

export default ExhibitionGroupAction;
