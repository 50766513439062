import { Button, Grid } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const GridMainFeature2 = ({
  xsGridLeft,
  mdGridLeft,
  xsGridRight,
  mdGridRight,
  src1,
  textSubContent2,
  textSubContent3,
  textSubContent4,
  textContent,
  textSubContent,
  textButton,
  gridStyle,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={gridStyle}
    >
      <Grid
        item
        xs={xsGridLeft}
        md={mdGridLeft}
      >
        <div className={classes.wrapDescriptionRight}>
          <Text className={classes.textContent}>{textContent}</Text>
          <Text className={classes.textSubContent}>{textSubContent}</Text>

          <Text className={classes.textSubContent2}>{textSubContent2}</Text>
          <Text className={classes.textSubContent2}>{textSubContent3}</Text>
          {textSubContent4 && <Text className={classes.textSubContent2}>{textSubContent4}</Text>}
          <Button
            variant="outlined"
            fullWidth
            className={classes.button}
          >
            <Text className={classes.textButton}>{textButton}</Text>
          </Button>
        </div>
      </Grid>

      <Grid
        item
        xs={xsGridRight}
        md={mdGridRight}
      >
        <div className={classes.wrapImageMainFeature}>
          <img
            className={classes.mainFeatureRight1}
            src={src1}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default GridMainFeature2;
