import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapEditionForm: {
    marginBottom: 24,
  },
  autocompleteStyle: {
    '&.MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: 3.5,
      },
    },
  },
  imageOption: {
    width: 53,
    height: 32,
    objectFit: 'cover',
    marginRight: 12,
  },
}));

export default useStyles;
