import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import API from 'configs/API';

import NotificationItem from './NotificationItem';

const NotificationList = () => {
  const { t } = useTranslation();
  const userId = useSelector((state) => state.auth.account.user.id);
  const { data: notifications = [] } = useQuery({
    queryKey: [API.AUTH.NOTIFICATION, null, userId],
  });

  return (
    <>
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          text={t('text_receive_request_certificate')}
          notification={notification}
        />
      ))}
    </>
  );
};

export default NotificationList;
