import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Stepper from 'commons/Stepper';
import { ExhibitionSchema } from 'utils/Validation';

import ExhibitionGroupStep from '../ExhibitionGroupStep';
import ExhibitionStepOne from '../ExhibitionStepOne';

const WrapStepperEditExhibition = ({ exhibition }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };

  const exhibitionFormState = useForm({
    values: {
      cover: exhibition?.cover_image,
      coverUploadFile: {},
      preface: exhibition?.preface,
      exhibitionTitle: exhibition?.title,
      dateStart: moment(exhibition?.date_start),
      dateEnd: moment(exhibition?.date_end),
      address: exhibition?.address,
      organizerName: exhibition?.organizer_name,
      typeOfEvent: exhibition?.event_type,
    },
    mode: 'onChange',
    resolver: yupResolver(ExhibitionSchema(t)),
  });

  return (
    <div>
      <Container>
        <Stepper active={step === 1}>
          <ExhibitionStepOne
            onNextStep={handleNextStep}
            exhibitionFormState={exhibitionFormState}
            step={step}
          />
        </Stepper>

        <Stepper active={step === 2}>
          <ExhibitionGroupStep
            step={step}
            setStep={setStep}
            exhibition={exhibition}
            informationFormState={exhibitionFormState}
          />
        </Stepper>
      </Container>
    </div>
  );
};

export default WrapStepperEditExhibition;
