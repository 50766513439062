import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';
import IosSwitch from 'commons/IosSwitch';
import Text from 'commons/Text';
import API from 'configs/API';
import { TESTID } from 'configs/Constant';
import DialogEditArtistProfile from 'pages/EditArtistProfile/commons/DialogEditArtistProfile';
import ExhibitionListGrid from 'pages/EditArtistProfile/commons/ExhibitionListGrid';
import SectionWrapper from 'pages/EditArtistProfile/commons/SectionWrapper';
import { isValidURL, isValidYear } from 'utils/Validation';
import { axiosDelete, axiosGet, axiosPost } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const ArtistExhibition = ({ isSoloExhibition }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [listExhibition, setListExhibition] = useState([]);
  const [year, setYear] = useState('');
  const [toYear, setToYear] = useState('');
  const [description, setDescription] = useState('');
  const [exhibitionLink, setExhibitionLink] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [wrongYear, setWrongYear] = useState(false);
  const [wrongToYear, setWrongToYear] = useState(false);
  const [wrongExhibitionLink, setWrongExhibitionLink] = useState(false);
  const [isShowDialogDelete, setIsShowDialogDelete] = useState(false);
  const [isShowDialogEdit, setIsShowDialogEdit] = useState(false);
  const [selectedExhibition, setSelectedExhibition] = useState({});

  const [editedYear, setEditedYear] = useState('');
  const [editedToYear, setEditedToYear] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedIsPublic, setEditedIsPublic] = useState(true);
  const [editedExhibitionLink, setEditedExhibitionLink] = useState(true);

  const isFormValid = description && (!wrongExhibitionLink || !exhibitionLink);

  const getListExhibition = async () => {
    const { success, data } = await axiosGet(
      isSoloExhibition ? API.AUTH.SOLO_EXHIBITION.LIST : API.AUTH.GROUP_EXHIBITION.LIST,
    );
    if (success) {
      setListExhibition(data);
    }
  };

  const handleChangeYear = (e) => {
    const value = e.target.value;
    setWrongYear(!isValidYear(value));
    if (value === '') {
      setWrongYear(false);
    }
    setYear(value);
  };

  const handleChangeToYear = (e) => {
    const value = e.target.value;
    setWrongToYear(!isValidYear(value));
    if (value === '') {
      setWrongToYear(false);
    }
    setToYear(value);
  };

  const handleChangeDescription = (e) => {
    const value = e.target.value;
    if (value?.length > 200) {
      return;
    }
    setDescription(value);
  };

  const handleChangeLink = (e) => {
    const url = e.target.value;
    setWrongExhibitionLink(!isValidURL(url));
    setExhibitionLink(url);
  };

  const handleSwitchChange = (e) => {
    const isHidePrice = e.target.checked;
    setIsPublic(isHidePrice);
  };

  const createUserExhibition = async () => {
    if (year && toYear && parseInt(year) > parseInt(toYear)) {
      return ToastTopHelper.error(t('to_year_cannot_earlier_than_year'));
    }
    const formData = {
      year: year || null,
      to_year: toYear || null,
      description: description,
      is_public: isPublic,
      exhibition_link: exhibitionLink,
    };
    const { success } = await axiosPost(
      isSoloExhibition ? API.AUTH.SOLO_EXHIBITION.CREATE : API.AUTH.GROUP_EXHIBITION.CREATE,
      formData,
    );
    if (success) {
      getListExhibition();
      setDescription('');
      setYear('');
      setToYear('');
      setExhibitionLink('');
      setIsPublic(true);
      ToastTopHelper.success(t('create_successfully'));
    }
  };

  const handleSubmitExhibitionDeletion = async () => {
    const { success } = await axiosDelete(
      isSoloExhibition
        ? API.AUTH.SOLO_EXHIBITION.DETAIL(selectedExhibition?.id)
        : API.AUTH.GROUP_EXHIBITION.DETAIL(selectedExhibition?.id),
    );
    if (success) {
      getListExhibition();
      setIsShowDialogDelete(false);
      ToastTopHelper.success(t('delete_success'));
    }
  };

  const handleCancelExhibitionDeletion = () => {
    setIsShowDialogDelete(false);
  };

  const handleOpenDialogDelete = (item) => {
    setSelectedExhibition(item);
    setIsShowDialogDelete(true);
  };

  const handleOpenDialogEdit = (item) => {
    setSelectedExhibition(item);
    setEditedYear(item?.year);
    setEditedToYear(item?.to_year);
    setEditedDescription(item?.description);
    setEditedIsPublic(item?.is_public);
    setEditedExhibitionLink(item?.exhibition_link);
    setIsShowDialogEdit(true);
    setWrongYear(false);
    setWrongToYear(false);
  };

  useEffect(() => {
    getListExhibition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionWrapper heading={isSoloExhibition ? t('solo_exhibitions') : t('group_exhibitions')}>
      <Box className={classes.bodyBox}>
        <div className={classes.wrapInput}>
          <FormInput
            value={year}
            label={t('from')}
            placeholder={t('enter_year_leave_blank_if_unknown')}
            onChange={handleChangeYear}
            InputProps={{
              inputProps: { type: 'number', step: '1' },
            }}
            error={!!wrongYear}
            helperText={wrongYear && t('please_enter_correct_year')}
            textFieldStyle={classes.textFieldStyle}
            wrapFormInputStyle={classes.wrapFormYearInput}
          />
          <FormInput
            value={toYear}
            label={t('to')}
            placeholder={t('enter_year_leave_blank_if_unknown')}
            onChange={handleChangeToYear}
            InputProps={{
              inputProps: { type: 'number', step: '1' },
            }}
            error={!!wrongToYear}
            helperText={wrongToYear && t('please_enter_correct_year')}
            textFieldStyle={classes.textFieldStyle}
            wrapFormInputStyle={classes.wrapFormYearInput}
          />
        </div>

        <FormInput
          value={description}
          label={t('description')}
          placeholder={t('write_something_to_describe')}
          onChange={handleChangeDescription}
          textFieldStyle={classes.textFieldStyle}
          wrapFormInputStyle={classes.wrapFormDescriptionInput}
        />

        <FormInput
          value={exhibitionLink}
          label={t('exhibition_link')}
          placeholder={t('enter_an_url')}
          onChange={handleChangeLink}
          error={wrongExhibitionLink}
          helperText={wrongExhibitionLink && t('please_enter_correct_link')}
          textFieldStyle={classes.textFieldStyle}
          testid={TESTID.FORM_INPUT_EXHIBITION_LINK}
        />
        <div className={classes.wrapIosSwitch}>
          <IosSwitch
            isChecked={isPublic}
            onSwitchChange={handleSwitchChange}
            testid={TESTID.IOS_SWITCH_EXHIBITION}
          />
          <Text
            className={classes.textPublicProfile}
            mt={12}
          >
            {isPublic ? t('public_on_profile') : t('private')}
          </Text>
        </div>
        <div className={classes.wrapButtonAdd}>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={createUserExhibition}
            className={classes.buttonAdd}
            testid={TESTID.BUTTON_ADD_EXHIBITION}
          >
            {t('add')}
          </Button>
        </div>
      </Box>
      <Text className={classes.textAddEventAutomatic}>{t('added_events_will_automatically')}</Text>
      <ExhibitionListGrid
        listExhibition={listExhibition}
        onClickOpenDialogDelete={handleOpenDialogDelete}
        onClickOpenDialogEdit={handleOpenDialogEdit}
      />

      <DialogEditArtistProfile
        dialogTitle={isSoloExhibition ? t('edit_solo_exhibition') : t('edit_group_exhibition')}
        isShowDialog={isShowDialogEdit}
        editedYear={editedYear}
        editedToYear={editedToYear}
        editedDescription={editedDescription}
        editedIsPublic={editedIsPublic}
        selectedExhibition={selectedExhibition}
        editedExhibitionLink={editedExhibitionLink}
        setEditYear={setEditedYear}
        setEditToYear={setEditedToYear}
        setEditDescription={setEditedDescription}
        setEditedIsPublic={setEditedIsPublic}
        setEditedExhibitionLink={setEditedExhibitionLink}
        setIsShowDialogEdit={setIsShowDialogEdit}
        getListExhibition={getListExhibition}
        isSoloExhibition={isSoloExhibition}
      />
      <DialogCustom
        isShowDialog={isShowDialogDelete}
        textCancel={t('cancel')}
        dialogTitle={t('delete_exhibition')}
        textSubmit={t('delete')}
        onCancel={handleCancelExhibitionDeletion}
        onSubmit={handleSubmitExhibitionDeletion}
        testid={TESTID.DIALOG_BUTTON_DELETE_EXHIBITION}
      >
        {t('are_you_sure_you_want_delete_your', {
          name: isSoloExhibition ? t('solo_exhibitions') : t('group_exhibitions'),
        })}
        <Text
          bold
          mt={4}
        >
          {selectedExhibition?.description}
        </Text>
      </DialogCustom>
    </SectionWrapper>
  );
};

export default ArtistExhibition;
