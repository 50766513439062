import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckBoxCustom from 'commons/CheckBoxCustom';
import FormInput from 'commons/Form/FormInput';
import { TESTID } from 'configs/Constant';
import AddRowInput from 'pages/EditArtistProfile/commons/AddRowInput';

import ContentGroup from '../ContentGroup';
import useStyles from './styles';

const TrainingBackground = ({ trainingBackground, setTrainingBackground, errorYear }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClickAdd = () => {
    setTrainingBackground([
      ...trainingBackground,
      { year: '', description: '', to_year: '', isChecked: false },
    ]);
  };

  const handleChangeYear = (e, index) => {
    const newPeriodTime = [...trainingBackground];
    newPeriodTime[index].year = e?.target?.value;
    setTrainingBackground(newPeriodTime);
  };

  const handleChangeToYear = (e, index) => {
    const newPeriodTime = [...trainingBackground];
    newPeriodTime[index].to_year = e?.target?.value;
    setTrainingBackground(newPeriodTime);
  };

  const handleChangeDescription = (e, index) => {
    const newPeriodTime = [...trainingBackground];
    newPeriodTime[index].description = e?.target?.value;
    setTrainingBackground(newPeriodTime);
  };

  const handleCheckBox = (e, index) => {
    const newPeriodTime = [...trainingBackground];
    newPeriodTime[index].isChecked = e?.target?.checked;
    if (newPeriodTime[index].isChecked) {
      newPeriodTime[index].year = '';
      newPeriodTime[index].to_year = '';
    }
    setTrainingBackground(newPeriodTime);
  };

  return (
    <ContentGroup heading={t('training_background')}>
      <div>
        {trainingBackground.map((item, index) => (
          <div
            key={index}
            className={classes.wrapBox}
          >
            <div style={{ display: 'flex', gap: 20 }}>
              <div className={classes.wrapInput}>
                <FormInput
                  value={item?.year}
                  onChange={(e) => handleChangeYear(e, index)}
                  label={t('from')}
                  placeholder={t('enter_year_leave_blank_if_unknown')}
                  wrapFormInputStyle={classes.wrapFormInput}
                  InputProps={{
                    inputProps: { type: 'number', step: '1' },
                  }}
                  error={!!errorYear(item)}
                  helperText={errorYear(item)}
                  testid={`${TESTID.INPUT_FIELD}_YEAR`}
                  disabled={item.isChecked}
                />
              </div>

              <div className={classes.wrapInput}>
                <FormInput
                  value={item?.to_year}
                  onChange={(e) => handleChangeToYear(e, index)}
                  label={t('to')}
                  placeholder={t('enter_year_leave_blank_if_unknown')}
                  wrapFormInputStyle={classes.wrapFormInput}
                  InputProps={{
                    inputProps: { type: 'number', step: '1' },
                  }}
                  disabled={item.isChecked}
                />
              </div>
            </div>

            <CheckBoxCustom
              textCheckBox={t('no_year_infomation')}
              checked={!!item.isChecked}
              onChange={(e) => handleCheckBox(e, index)}
              labelText={classes.labelText}
            />

            <FormInput
              value={item?.description}
              onChange={(e) => handleChangeDescription(e, index)}
              label={t('description')}
              placeholder={t('text_placeholder_training_background')}
              wrapFormInputStyle={classes.wrapFormInput}
              testid={`${TESTID.INPUT_FIELD}_DESCRIPTION`}
            />
          </div>
        ))}
      </div>

      <AddRowInput
        text={t('add_training_background')}
        onClick={handleClickAdd}
      />
    </ContentGroup>
  );
};

export default TrainingBackground;
