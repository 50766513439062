import { Avatar, Divider } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Text from 'commons/Text';

import useReadNotification from '../hooks/useReadNotification';
import useStyles from '../styles';

const NotificationItem = ({ notification }) => {
  const classes = useStyles();

  const language = useSelector((state) => state?.language?.currentLanguage);
  const { mutate: readNotification } = useReadNotification();
  const notificationText =
    language === 'en' ? notification?.template?.content_en : notification?.template?.content_vi;

  const formatText = notificationText
    ?.split(/(\{.*?\})/g)
    .filter(Boolean)
    .map((part, index) => {
      const key = part.replace(/\{|\}/g, '');
      const params = notification?.params;
      return params[key] ? <strong key={index}>{params[key]}</strong> : part;
    });

  const handleClick = async () => {
    readNotification(notification?.id);
  };

  return (
    <Link
      to={notification?.redirect_url || null}
      onClick={handleClick}
    >
      <div className={classes.notificationItemWrapper}>
        <div className={classes.notificationWrapContent}>
          <div className={classes.wrapNotiIcon}>
            <Avatar
              className={classes.menuItemAvatar}
              src={notification?.icon}
            />
            {!notification?.is_read && <div className={classes.unreadNoti} />}
          </div>
          <Text>{formatText}</Text>
        </div>

        {/* <div>
          <img
            src={notification?.params?.artwork_image}
            className={classes.notificationImage}
          />
        </div> */}
      </div>

      <Divider sx={{ mt: 2 }} />
    </Link>
  );
};

export default NotificationItem;
