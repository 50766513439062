import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const ExhibitionGroupActionItem = ({ title, onSelectGroup, index, artworks }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.category}>
      <div className={classes.icon} />
      <div className={classes.details}>
        <Text
          variant="h3"
          clickAble
          onClick={() => onSelectGroup(index)}
        >
          {title}
        </Text>
        <Text
          type="sm"
          mt={8}
        >
          {artworks?.length} {t('artworks')}
        </Text>
      </div>
    </div>
  );
};

export default ExhibitionGroupActionItem;
