import { TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TableColumn from 'commons/TableColumn';

import useStyles from './styles';

const TableArtistShowAllHead = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableColumn
          text={t('#')}
          colStyle={classes.columnMini}
        />

        <TableColumn
          text={t('artwork_name')}
          colStyle={classes.columnLongWidth}
        />

        <TableColumn
          text={t('visibility')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('medium')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('year')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('measurement')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('price')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('edition_volume')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('certification')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('location')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('status')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('action')}
          colStyle={classes.columnWidth}
        />
      </TableRow>
    </TableHead>
  );
};

export default TableArtistShowAllHead;
