import { CircularProgress, InputAdornment } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormAutocompleteController from 'commons/FormHook/FormAutocompleteController';
import Text from 'commons/Text';
import API from 'configs/API';
import useDebounce from 'hooks/useDebounce';

import useStyles from './styles';

const MatchingBox = ({
  artworkTitle,
  totalEdition,
  artworkFormName,
  editionFormName,
  onArtworkChange,
  hookForm,
  disabled,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { formState, watch, control } = hookForm;

  const artworkValue = watch('artworkValue');

  const user = useSelector((state) => state.auth.account.user);
  const [inputValue, setInputValue] = useState('');
  const inputValueDebounce = useDebounce(inputValue || artworkTitle);

  const { data: artworks, isPending } = useQuery({
    queryKey: [API.ARTWORK.SEARCH_AUTOCOMPLETE, { owner: user?.id, search: inputValueDebounce }],
  });
  const { data: editionDropDown } = useQuery({
    queryKey: [API.ARTWORK.EDITIONS_CAN_REQUEST_CERTIFICATE(artworkValue?.id), artworkValue?.id],
    enabled: !!artworkValue?.id,
  });

  const handleInputChange = (_, value) => {
    setInputValue(value);
  };

  const generateEditionOptions = useMemo(() => {
    const editions = [];

    for (let i = 1; i <= totalEdition; i++) {
      editions.push({ edition_number: i });
    }

    return editions;
  }, [totalEdition]);

  const artworkOptions =
    artworks && artworks?.length
      ? [{ title: t('new_artwork'), addNew: true }, ...artworks]
      : [{ title: t('new_artwork'), addNew: true }];

  const editionOptions = artworkValue?.addNew ? generateEditionOptions : editionDropDown;

  return (
    <>
      <Text
        fontWeightBold
        type="xl"
      >
        {t('matching')}
      </Text>

      <Text
        type="sm"
        mt={8}
      >
        {t('text_approve_certificate_request')}
      </Text>

      <FormAutocompleteController
        control={control}
        name={artworkFormName}
        onChange={onArtworkChange}
        options={artworkOptions}
        label={t('artwork')}
        labelProps={{ type: 'sm' }}
        getOptionLabel={(option) => option?.title}
        renderOption={(props, option) => {
          return (
            <div {...props}>
              {option?.images && (
                <img
                  src={option?.images[0]}
                  className={classes.imageOption}
                />
              )}

              {option?.title}
            </div>
          );
        }}
        getOptionKey={(option) => option?.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        freeSolo
        endAdornment={
          isPending && (
            <InputAdornment position="end">
              <CircularProgress
                color="inherit"
                size={16}
              />
            </InputAdornment>
          )
        }
        filterOptions={(options) => options}
        helperText={formState?.errors[artworkFormName]?.message}
        error={!!formState?.errors[artworkFormName]}
        disabled={disabled}
        autocompleteStyle={classes.autocompleteStyle}
      />

      <FormAutocompleteController
        control={control}
        name={editionFormName}
        options={editionOptions}
        label={t('edition_number')}
        getOptionLabel={(option) => option?.edition_number?.toString()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        helperText={formState?.errors[editionFormName]?.message}
        error={!!formState?.errors[editionFormName]}
        disabled={disabled}
        autocompleteStyle={classes.autocompleteStyle}
        wrapFormStyle={classes.wrapEditionForm}
      />
    </>
  );
};

export default MatchingBox;
