import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapBox: {
    border: `1px solid ${Colors.Gray3}`,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    height: 624,
    padding: 24,
    background: Colors.White,
  },
  wrapTextWarning: {
    marginTop: 8,
  },
  wrapFormInput: {
    marginTop: 24,
  },
  iconSuccess: {
    color: Colors.Green6,
  },
}));

export default useStyles;
