import { Box, ImageList, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PaginationCustom from 'commons/PaginationCustom';
import Text from 'commons/Text';
import { BREAKPOINTS } from 'configs/Constant';
import usePagination from 'hooks/usePagination';
import useGalleryArtworks from 'pages/Gallery/hooks/useGalleryArtworks';
import useStatusCanRequestCertificate from 'react-query/hooks/queries/useStatusCanRequestCertificate';
import { useQueryParameters } from 'utils/utils';

import GalleryArtworkItem from '../GalleryArtworkItem';
import TabArtworkHeader from '../TabArtworkHeader';
import useStyles from './styles';

const GalleryArtwork = ({ token, isOwner }) => {
  const { id } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const query = useQueryParameters();
  const searchValue = query.get('search');

  const { data: artworks, isPending } = useGalleryArtworks(id, token);
  const { data: status } = useStatusCanRequestCertificate();
  const { page, totalPage, onPageChange } = usePagination(artworks?.count, artworks?.page_size);

  const isLaptop = useMediaQuery(BREAKPOINTS.laptop);
  const isMobile = useMediaQuery(BREAKPOINTS.mobile);

  if (isPending) {
    return;
  }

  const listArtwork = artworks?.results;

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const getColumns = () => {
    if (isLaptop) {
      return 4;
    }
    if (isMobile) {
      return 1;
    }
    return 2; //tablet
  };

  return (
    <Box sx={{ mt: 3 }}>
      <TabArtworkHeader
        placeholder={t('search_id_artwork_name')}
        isOwner={isOwner}
      />

      <ImageList
        variant="masonry"
        cols={getColumns()}
        gap={40}
        sx={{ mt: 3 }}
      >
        <>
          {listArtwork?.map((artwork) => (
            <GalleryArtworkItem
              artwork={artwork}
              status={status}
              key={artwork?.id}
              isOwner={isOwner}
            />
          ))}
        </>
      </ImageList>

      {searchValue && artworks?.count === 0 && (
        <div className={classes.wrapTextNoResult}>
          <Text>{t('no_results_that_match_your_search')}</Text>
          <Text>{t('please_try_adjust_your_keywords')}</Text>
        </div>
      )}

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default GalleryArtwork;
