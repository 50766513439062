import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  btnFilter: {
    '&.MuiButton-root': {
      marginTop: 24,
      gap: '4px',
      border: `1px solid ${Colors.Primary}`,
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      marginTop: 6,
    },
    '& .MuiMenu-list': {
      padding: 8,
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      minWidth: 158,
      padding: 8,
    },
  },
  btnFilterActive: {
    '&.MuiButton-root': {
      background: Colors.Gray5,
    },
  },
}));

export default useStyles;
