import { useState, useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTimeout = useRef();
  const location = useLocation();
  const { title } = location.state || {};
  const searchValueParams = searchParams.get('search') || '';
  const [search, setSearch] = useState(searchValueParams);

  const handleSearch = (e) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    const value = e.target.value;
    setSearch(value);

    if (!value) {
      searchTimeout.current = setTimeout(() => {
        searchParams.delete('search');
        searchParams.delete('page');
        setSearchParams(searchParams);
      }, 1000);
      return;
    }

    searchTimeout.current = setTimeout(() => {
      searchParams.set('search', value);
      searchParams.delete('page');
      setSearchParams(searchParams);
    }, 1000);
  };

  useEffect(() => {
    setSearch(searchValueParams);
  }, [searchValueParams]);

  useEffect(() => {
    if (title) {
      searchParams.set('search', title || '');
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { search, handleSearch };
};
