import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingBottom: 24,
    borderBottom: `1px solid ${Colors.Grey3}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchWidth: {
    width: '48%',
  },
}));

export default useStyles;
