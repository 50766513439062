import LockIcon from '@mui/icons-material/Lock';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Popover } from '@mui/material';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DialogCustom from 'commons/DialogCustom';
import useDeleteExhibition from 'commons/Exhibition/hooks/useDeleteExhibition';
import Text from 'commons/Text';
import Colors from 'configs/Colors';
import Routes from 'utils/Route';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const ExhibitionPopover = ({ dotStyle, exhibitionId, isPublicExhibition }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const { mutate: deleteExhibition } = useDeleteExhibition();

  const handleOpenPopover = (e) => {
    e?.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e?.preventDefault();
    setAnchorEl(null);
  };

  const handleShowPopUpConfirmDelete = (e) => {
    e?.preventDefault();
    handleClose();
    setIsShowDialog(true);
  };

  const handleDeleteExhibition = (e) => {
    e.preventDefault();
    deleteExhibition(exhibitionId, {
      onSuccess: () => {
        ToastTopHelper.success(t('delete_success'));
      },
    });
    setIsShowDialog(false);
  };

  const handleCancelDeleteExhibition = (e) => {
    e.preventDefault();
    setIsShowDialog(false);
  };

  const handleNavigateEditExhibition = (e) => {
    e.preventDefault();
    navigate(Routes.EditExhibition(exhibitionId));
  };

  const handleShareExhibition = (e) => {
    e.preventDefault();
    const link = `${window.location.origin}${Routes.ExhibitionDetail(exhibitionId)}`;
    const copied = copy(link);

    if (copied) {
      ToastTopHelper.success(t('link_has_been_copied'));
    }
  };
  return (
    <>
      {!isPublicExhibition && (
        <div
          className={clsx(
            classes.wraplock,
            {
              [classes.dotActive]: !!anchorEl,
            },
            dotStyle,
          )}
        >
          <LockIcon className={classes.iconDots} />
        </div>
      )}

      <div
        className={clsx(
          classes.wrapdots,
          {
            [classes.dotActive]: !!anchorEl,
          },
          dotStyle,
        )}
        onClick={handleOpenPopover}
      >
        <MoreHorizIcon className={classes.iconDots} />
      </div>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <div className={classes.wrapPopoverContent2}>
          <Text
            clickAble
            onClick={handleNavigateEditExhibition}
          >
            {t('edit_exhibition')}
          </Text>
        </div>

        <div className={classes.wrapPopoverContent2}>
          <Text
            clickAble
            onClick={handleShareExhibition}
          >
            {t('share_exhibition')}
          </Text>
        </div>

        <div className={classes.wrapPopoverContent}>
          <Text
            color={Colors.Error}
            clickAble
            onClick={handleShowPopUpConfirmDelete}
          >
            {t('delete_exhibition')}
          </Text>
        </div>
      </Popover>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('delete_exhibition')}
        textCancel={t('cancel')}
        textSubmit={t('delete')}
        onCancel={handleCancelDeleteExhibition}
        onSubmit={handleDeleteExhibition}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
        cancelButtonStyle={clsx(classes.buttonDialog)}
      >
        {t('are_you_sure_to_delete_this_exhibition')}
      </DialogCustom>
    </>
  );
};

export default ExhibitionPopover;
