import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapHeader: {
    borderBottom: `1px solid ${Colors.Grey4}`,
    padding: '15px 0px',
  },
  row: {
    '&.MuiGrid-root': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  wrapHeaderLeft: {
    display: 'flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
  },
  wrapGroupItem: {
    display: 'flex',
    alignItems: 'center',
  },
  textItem: {
    padding: 12,
    fontWeight: 200,
    fontSize: 16,
    letterSpacing: 0.64,
    cursor: 'pointer',
    fontFamily: 'Readex Pro',
  },
  wrapButtonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonLogin: {
    '&.MuiButton-root': {
      width: 120,
      borderRadius: 20,
      marginRight: 12,
      marginLeft: 16,
    },
  },
  buttonSignUp: {
    '&.MuiButton-root': {
      width: 120,
      borderRadius: 20,
    },
  },
  avatarProfile: {
    '&.MuiAvatar-root': {
      width: 32,
      height: 32,
    },
  },
  wrapItemMenuProfile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textAlignItemProfile: {
    textAlign: 'center',
  },
  avatarItemMenuProfile: {
    '&.MuiAvatar-root': {
      width: '80px !important',
      height: '80px !important',
      margin: '0 auto !important',
      marginTop: '8px !important',
    },
  },
  textUserName: {
    marginTop: 8,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0.8px',
  },
  textEmail: {
    color: 'var(--grey-8, #595959)',
    textAlign: 'center',
    fontFamily: 'NuOrder Trial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.48px',
    marginBottom: 12,
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      textTransform: 'capitalize',
      fontWeight: 400,
    },
  },
  menuPaper: {
    elevation: 0,
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: 1.5,
    width: 300,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  footerItem: {
    paddingLeft: 16,
    color: Colors.Gray7,
  },
  logoPlatForm: {
    marginRight: 25,
  },
  logoPlatFormSmartPhone: {
    marginLeft: 16,
  },
  iconMenu: {
    '&.MuiSvgIcon-root': {
      width: 32,
      height: 32,
    },
  },
  textSiteLanguage: {
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '0.64px',
    marginLeft: 16,
    marginBottom: 8,
  },
  customMenuPaper: { width: 250 },
  textLanguage: {
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '0.64px',
    textTransform: 'uppercase',
  },
  boxMenuLanguage: {
    cursor: 'pointer',
  },
  iconArrow: {
    '&.MuiSvgIcon-root': {
      width: 28,
      height: 28,
      marginBottom: 2,
    },
  },
  wrapMenuLanguage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuLanguage: {
    '&.MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  textPlatformVersion: {
    fontWeight: 200,
    lineHeight: '120%',
    letterSpacing: 0.4,
    marginLeft: 16,
    marginTop: 2,
  },
  wrapIconMessage: {
    marginLeft: 24,
    display: 'inline-flex',
    position: 'relative',
    cursor: 'pointer',
  },
  messageCounterNumber: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: Colors.Error,
    color: Colors.White,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '60%',
    bottom: '60%',
  },
  menuItemAvatar: {
    '&.MuiAvatar-root': {
      width: '32px ',
      height: '32px ',
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      width: 404,
      height: 614,
      padding: 16,
    },
  },
  notificationItemWrapper: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',

    gap: 16,
  },
  notificationWrapContent: {
    display: 'flex',
    gap: 16,
  },
  notificationImage: {
    maxWidth: 40,
    maxHeight: 40,
    width: 40,
    height: 40,
    objectFit: 'cover',
  },
  unreadNoti: {
    width: 8,
    height: 8,
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: '50%',
    backgroundColor: Colors.Error,
  },
  wrapNotiIcon: {
    width: 32,
    height: 32,
    position: 'relative',
  },

  [mediaQueries.tabletMedium]: {
    buttonLogin: {
      '&.MuiButton-root': {
        width: 65,
        marginLeft: 2,
        marginRight: 2,
        whiteSpace: 'nowrap',
      },
    },
    boxMenuLanguage: {
      width: 50,
    },
    textButton: {
      fontSize: '12px !important',
    },
    textLanguage: {
      fontSize: '12px !important',
    },
  },
  [mediaQueries.mobile]: {
    buttonSignUp: {
      '&.MuiButton-root': {
        width: 78,
        whiteSpace: 'nowrap',
      },
    },

    boxMenuLanguage: {
      width: 50,
    },
  },

  '@keyframes ring': {
    '0%': { transform: 'rotate(0deg)' },
    '10%': { transform: 'rotate(-15deg)' },
    '20%': { transform: 'rotate(15deg)' },
    '30%': { transform: 'rotate(-15deg)' },
    '40%': { transform: 'rotate(15deg)' },
    '50%': { transform: 'rotate(0deg)' },
  },
  ringAnimation: {
    animation: '$ring 0.5s ease-in-out infinite',
  },
  iconWrapper: {
    display: 'inline-flex', // Chỉ áp dụng hiệu ứng rung vào đây
  },
}));

export default useStyles;
