import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';

const readNotification = async (id) => {
  const { data } = await axiosPost(false, API.AUTH.READ_NOTIFICATION(id));

  return data;
};

const useReadNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: readNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API.AUTH.NOTIFICATION],
      });
    },
  });
};

export default useReadNotification;
