import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 899,
    minWidth: 899,
    padding: 64,
    paddingBottom: 47,

    border: `1px solid ${Colors.Gray3}`,
    background: Colors.White,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    position: 'relative',
  },
  buttonDownload: {
    '&.MuiButton-root': {
      minWidth: 247,
      minHeight: 48,
      padding: '12px 20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 12,
      borderRadius: 999,
      background: 'rgba(0, 0, 0, 0.70)',
      position: 'absolute',
      left: '50%',
      bottom: 30,
      transform: 'translateX(-50%)',
    },
  },
}));

export default useStyles;
