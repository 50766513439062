import { ImageList } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import ExhibitionArtworkItem from '../ExhibitionArtworkItem';
import useStyles from './styles';

const ExhibitionArtworkGroup = ({ groups, owner }) => {
  const classes = useStyles();

  return (
    <>
      {groups?.map((group) => (
        <div
          className={classes.wrapper}
          key={group?.id}
          id={group?.id}
          name="artwork-group"
        >
          <Text
            type="lg"
            fontWeightBold
          >
            {group?.title}
          </Text>

          <Text
            mt={8}
            mb={24}
          >
            {group?.description}
          </Text>

          <ImageList
            variant="masonry"
            cols={3}
            gap={40}
          >
            {group?.artwork_links?.map((artwork) => (
              <ExhibitionArtworkItem
                key={artwork?.id}
                artwork={artwork}
                owner={owner}
              />
            ))}
          </ImageList>
        </div>
      ))}
    </>
  );
};

export default ExhibitionArtworkGroup;
