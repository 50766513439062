import { useMutation } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPatch } from 'utils/apis/axios';

const changePassword = async (formData) => {
  const { data } = await axiosPatch(false, API.AUTH.CHANGE_PASSWORD, formData);
  return data;
};

const useChangePassword = () => {
  return useMutation({
    mutationFn: changePassword,
  });
};

export default useChangePassword;
