import { Box, ImageList, useMediaQuery } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import PaginationCustom from 'commons/PaginationCustom';
import { BREAKPOINTS } from 'configs/Constant';

import CollectionArtworkItem from '../CollectionArtworkItem';
import useStyles from './styles';

const CollectionArtworkList = ({ collection }) => {
  const classes = useStyles();
  const isLaptop = useMediaQuery(BREAKPOINTS.laptop);
  const isMobile = useMediaQuery(BREAKPOINTS.mobile);
  const [searchParams, setSearchParams] = useSearchParams();

  const artworks = collection?.artworks || [];

  const totalPage = Math.ceil(artworks?.count / artworks?.page_size);
  const page = Number(searchParams.get('artworks_page')) || 1;

  const handlePageChange = (_, page) => {
    if (page <= 1) {
      searchParams.delete('artworks_page');
      setSearchParams(searchParams);
      return;
    }

    searchParams.set('artworks_page', page);
    setSearchParams(searchParams);
  };

  const getColumns = () => {
    if (isLaptop) {
      return 4;
    }
    if (isMobile) {
      return 1;
    }
    return 2; //tablet
  };

  return (
    <Box sx={{ mt: 5 }}>
      <ImageList
        variant="masonry"
        cols={getColumns()}
        gap={40}
      >
        {artworks?.results.map((artwork) => (
          <CollectionArtworkItem
            artwork={artwork}
            key={artwork?.id}
          />
        ))}
      </ImageList>

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default CollectionArtworkList;
