import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import API from 'configs/API';
import Routes from 'utils/Route';

import GalleryTabContent from './components/GalleryTabContent';
import GalleryWrapper from './components/GalleryWrapper';

const Gallery = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: userProfile,
    isPending,
    error,
  } = useQuery({
    queryKey: [
      API.AUTH.PROFILE_INFO,
      {
        user_uuid: id,
      },
    ],
  });

  if (isPending) {
    return;
  }

  if (error && error?.error?.response?.status === 404) {
    navigate(Routes.PageNotFound, { replace: true });
  }

  return (
    <Container sx={{ pb: 8 }}>
      <GalleryWrapper
        userProfile={userProfile}
        key={userProfile?.id}
      />
      <GalleryTabContent
        userProfile={userProfile}
        roleUser={userProfile?.role}
      />
    </Container>
  );
};

export default Gallery;
