import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapHearder: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 24,
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
  textArtists: {
    letterSpacing: '1.44px',
  },
  wrapSearch: {
    marginTop: 24,
    width: '48%',
  },
}));

export default useStyles;
