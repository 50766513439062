import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';
import { ReactComponent as EyeCloseIcon } from 'images/eye_close.svg';
import { ReactComponent as EyeOpenIcon } from 'images/eye_open.svg';
import { ToastTopHelper } from 'utils/utils';

import useChangePassword from './hooks/useChangePassword';
import useStyles from './styles';
import { passwordSchema } from './validate';

const ChangePassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { mutate: changePassword } = useChangePassword();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { register, watch, handleSubmit, formState } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(passwordSchema(t)),
  });

  const password = watch('password');

  const isValidLength = password?.length >= 8;
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumberOrSymbol = /[0-9!@#$%]/.test(password);

  const handleChangePassword = (data) => {
    changePassword(
      {
        old_password: data?.oldPassword,
        new_password: data?.password,
        confirm_password: data?.confirmPassword,
      },
      {
        onSuccess: () => {
          ToastTopHelper.success(t('update_successfully'));
        },
      },
    );
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <Box className={classes.wrapBox}>
      <Text
        type="2xl"
        title
        ucfirst
        className={classes.heading}
      >
        {t('change_password')}
      </Text>

      <FormInput
        {...register('oldPassword')}
        error={!!formState?.errors.oldPassword}
        helperText={formState?.errors.oldPassword?.message}
        label={t('current_password')}
        placeholder={t('enter_your_current_password')}
        wrapFormInputStyle={classes.wrapFormInput}
        isRequired
        type={showPassword ? 'text' : 'password'}
        iconEndAdorment={
          showPassword ? (
            <EyeOpenIcon onClick={togglePasswordVisibility} />
          ) : (
            <EyeCloseIcon onClick={togglePasswordVisibility} />
          )
        }
      />

      <FormInput
        {...register('password')}
        label={t('new_password')}
        placeholder={t('enter_your_new_password')}
        isRequired
        wrapFormInputStyle={classes.wrapFormInput}
        type={showPassword ? 'text' : 'password'}
        iconEndAdorment={
          showPassword ? (
            <EyeOpenIcon onClick={togglePasswordVisibility} />
          ) : (
            <EyeCloseIcon onClick={togglePasswordVisibility} />
          )
        }
      />

      <div className={classes.wrapTextWarning}>
        <Text>{t('your_password_must')}</Text>
        <Stack
          direction="row"
          spacing={1}
        >
          <CheckIcon className={isValidLength ? classes.iconSuccess : ''} />
          <Text>{t('be_at_least_8_character')}</Text>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
        >
          <CheckIcon className={hasLowercase ? classes.iconSuccess : ''} />
          <Text>{t('have_at_least_one_lower_case_character')}</Text>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
        >
          <CheckIcon className={hasUppercase ? classes.iconSuccess : ''} />
          <Text>{t('have_at_least_one_capital_character')}</Text>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
        >
          <CheckIcon className={hasNumberOrSymbol ? classes.iconSuccess : ''} />
          <Text>{t('have_at_least_one_number_or_symbol')}</Text>
        </Stack>
      </div>

      <FormInput
        {...register('confirmPassword')}
        error={!!formState?.errors.confirmPassword}
        helperText={formState?.errors.confirmPassword?.message}
        label={t('confirm_new_password')}
        placeholder={t('confirm_your_new_password')}
        isRequired
        wrapFormInputStyle={classes.wrapFormInput}
        type={showConfirmPassword ? 'text' : 'password'}
        iconEndAdorment={
          showConfirmPassword ? (
            <EyeOpenIcon onClick={toggleConfirmPasswordVisibility} />
          ) : (
            <EyeCloseIcon onClick={toggleConfirmPasswordVisibility} />
          )
        }
      />

      <Button
        variant="contained"
        sx={{ mt: 3 }}
        onClick={handleSubmit(handleChangePassword)}
      >
        {t('change_password')}
      </Button>
    </Box>
  );
};

export default ChangePassword;
