import { Button, Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Text from 'commons/Text';
import { ARTWORK_FILTER_PARAMS, SORT_ARTIST } from 'configs/FilterArtwork';
import { ReactComponent as FilterSort } from 'images/filter_sort.svg';

import useStyles from './styles';

const { ORDERING } = ARTWORK_FILTER_PARAMS;
const SortArtist = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSort, setShowSort] = useState(false);

  const open = Boolean(anchorEl);

  const handleShowSort = (e) => {
    setAnchorEl(e.currentTarget);
    setShowSort(!showSort);
  };

  const handleCloseSort = () => {
    setAnchorEl(null);
    setShowSort(false);
  };

  const handleSortYearAsc = () => {
    searchParams.set(ORDERING, SORT_ARTIST.YEAR_ASC);
    searchParams.delete('page');
    setSearchParams(searchParams);
    handleCloseSort();
  };

  const handleSortYearDesc = () => {
    searchParams.set(ORDERING, SORT_ARTIST.YEAR_DESC);
    searchParams.delete('page');
    setSearchParams(searchParams);
    handleCloseSort();
  };

  const handleSortAlphaAsc = () => {
    searchParams.set(ORDERING, SORT_ARTIST.ALPHABET_ASC);
    searchParams.delete('page');
    setSearchParams(searchParams);
    handleCloseSort();
  };

  const handleSortAlphaDesc = () => {
    searchParams.set(ORDERING, SORT_ARTIST.ALPHABET_DESC);
    searchParams.delete('page');
    setSearchParams(searchParams);
    handleCloseSort();
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleShowSort}
        className={clsx(classes.btnFilter, {
          [classes.btnFilterActive]: showSort,
        })}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <FilterSort />
        {t('sort')}
      </Button>

      <Menu
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={open}
        onClose={handleCloseSort}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={classes.menu}
      >
        <MenuItem
          onClick={handleSortYearAsc}
          className={classes.menuItem}
          key="price-asc"
        >
          <Text type="sm">{t('year_of_birth_earliest_most_recent')}</Text>
        </MenuItem>

        <MenuItem
          onClick={handleSortYearDesc}
          className={classes.menuItem}
          key="price-desc"
        >
          <Text type="sm">{t('year_of_birth_most_recent_earliest')}</Text>
        </MenuItem>

        <MenuItem
          onClick={handleSortAlphaAsc}
          className={classes.menuItem}
          key="date-asc"
        >
          <Text type="sm">{t('alphabetical_a_z')}</Text>
        </MenuItem>

        <MenuItem
          onClick={handleSortAlphaDesc}
          className={classes.menuItem}
          key="date-desc"
        >
          <Text type="sm">{t('alphabetical_z_a')}</Text>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SortArtist;
