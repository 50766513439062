import { Box, Grid } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import DialogCustom from 'commons/DialogCustom';
import FilterArtwork from 'commons/FilterArtwork';
import FilterTags from 'commons/FilterArtwork/FilterTags';
import SearchInputIcon from 'commons/Search/SearchInputIcon';
import Text from 'commons/Text';
import { useSearch } from 'hooks/useSearch';
import FilterPanel from 'pages/ManageArtwork/components/ManageArtworkPanel/FilterPanel';
import { updateFilterResults } from 'redux/reducer/manageArtworkSlice';

import useStyles from '../styles';
import SelectedArtworksItem from './SelectedArtworksItem';

const DialogSelectArtworks = ({
  isShowDialog,
  selectedArtworks,
  artworks,
  onCancel,
  onSubmit,
  onScroll,
  onClickSelectArtworkItem,
  dialogTitle,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { search, handleSearch } = useSearch();
  const [showFilter, setShowFilter] = useState(false);
  const isFetching = useIsFetching();
  const [numberFilter, setNumberFilter] = useState(0);
  const [searchParams] = useSearchParams();
  const count = artworks?.pages[0].count || 0;

  const handleShowFilter = () => setShowFilter(!showFilter);

  useEffect(() => {
    const numberFilter = document.querySelectorAll('[data-group=filter-tag]')?.length || 0;
    setNumberFilter(numberFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, searchParams.toString()]);

  useEffect(() => {
    dispatch(updateFilterResults(count));
  }, [count, dispatch]);

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      dialogTitle={dialogTitle}
      textCancel={t('cancel')}
      textSubmit={t('add')}
      textBottom={`${selectedArtworks?.length} ${t('selected')}`}
      maxWidth={false}
      PaperProps={{
        className: classes.dialogPaper,
      }}
      onCancel={onCancel}
      onSubmit={onSubmit}
      typeDialogTitle={'xl'}
      submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
      cancelButtonStyle={clsx(classes.buttonDialog)}
    >
      <div
        className={classes.wrapDialogContent}
        onScroll={onScroll}
      >
        <Text fontWeightBold>{t('select_artworks')}</Text>
        <div className={classes.wrapSearchAndSort}>
          <SearchInputIcon
            value={search}
            onChange={handleSearch}
            placeholder={t('search_id_artwork_name')}
            wrapStyle={classes.wrapSearchInput}
          />
          <FilterPanel
            showFilter={showFilter}
            onShowFilterChange={handleShowFilter}
            numberFilter={numberFilter}
          />
        </div>

        <div>{showFilter && <FilterArtwork numberFilter={numberFilter} />}</div>

        {!showFilter && (
          <FilterTags
            numberFilter={numberFilter}
            wrapFilterStyle={clsx(classes.wrapFilterTag, {
              [classes.hiddenTag]: !numberFilter,
            })}
          />
        )}

        <Box className={classes.wrapArtworksSelection}>
          {artworks?.pages[0]?.count === 0 ? (
            <div className={classes.wrapTextNoResult}>
              <Text>{t('no_results_that_match_your_search')}</Text>
              <Text>{t('please_try_adjust_your_keywords')}</Text>
            </div>
          ) : (
            <Grid
              container
              spacing={2}
            >
              {artworks?.pages?.map((group, i) => (
                <React.Fragment key={i}>
                  {group.results.map((artwork) => (
                    <Grid
                      item
                      xs={12}
                      lg={2}
                      key={artwork.id}
                    >
                      <SelectedArtworksItem
                        artwork={artwork}
                        selectedArtworks={selectedArtworks}
                        onClick={onClickSelectArtworkItem(artwork)}
                      />
                    </Grid>
                  ))}
                </React.Fragment>
              ))}
            </Grid>
          )}
        </Box>
      </div>
    </DialogCustom>
  );
};

export default DialogSelectArtworks;
