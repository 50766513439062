import { Box, Button } from '@mui/material';
import clsx from 'clsx';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import useRole from 'hooks/useRole';
import { ReactComponent as DownloadIcon } from 'images/download.svg';

import CertificateArtworkInfo from './components/CertificateArtworkInfo';
import CertificateFooter from './components/CertificateFooter';
import CertificateHeader from './components/CertificateHeader';
import CertificateOwnerInfo from './components/CertificateOwnerInfo';
import useStyles from './styles';

const Certificate = forwardRef(
  (
    { artwork, certificate, isShowButtonDownloadCertificate, wrapperStyle, wrapperInlineStyle },
    ref,
  ) => {
    const classes = useStyles();
    const certificateRef = useRef(null);
    const { t } = useTranslation();
    const { isArtist } = useRole();

    useImperativeHandle(ref, () => certificateRef.current);

    const downloadPDF = async () => {
      const content = certificateRef.current;

      if (!content) {
        console.error('certificateRef.current is null');
        return;
      }

      try {
        const canvas = await html2canvas(content, {
          useCORS: true,
          scale: 2,
          ignoreElements: (element) => element.classList.contains(classes.buttonDownload),
        });

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const scale = pageHeight / canvas.height;

        const imgScaledWidth = canvas.width * scale;
        const imgScaledHeight = pageHeight;

        const offsetX = (pageWidth - imgScaledWidth) / 2;
        const offsetY = 0;

        const paddingHorizontal = 10;
        const paddedWidth = imgScaledWidth - 2 * paddingHorizontal;
        const paddedOffsetX = offsetX + paddingHorizontal;

        pdf.addImage(imgData, 'PNG', paddedOffsetX, offsetY, paddedWidth, imgScaledHeight);
        pdf.save('certificate.pdf');
      } catch (error) {
        console.error('Lỗi khi tạo PDF:', error);
      }
    };

    return (
      <Box
        ref={certificateRef}
        className={clsx(classes.wrapper, wrapperStyle)}
        style={wrapperInlineStyle}
      >
        <CertificateHeader certificate={certificate} />
        <CertificateArtworkInfo
          artwork={artwork}
          certificate={certificate}
        />

        <CertificateOwnerInfo />
        <CertificateFooter />

        {isArtist && isShowButtonDownloadCertificate && (
          <Button
            variant="contained"
            color="primary"
            onClick={downloadPDF}
            className={classes.buttonDownload}
          >
            <DownloadIcon />
            <Text>{t('download_certificate')}</Text>
          </Button>
        )}
      </Box>
    );
  },
);

export default Certificate;
