import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import MainFeature1 from '../../../../images/MainFeatures/main_feature1.png';
import MainFeature4 from '../../../../images/MainFeatures/main_feature4.png';
import MainFeature8 from '../../../../images/MainFeatures/main_feature8.png';
import MainFeature10 from '../../../../images/MainFeatures/main_feature10.png';
import GridMainFeature1 from './GridMainFeature1';
import GridMainFeature2 from './GridMainFeature2';
import useStyles from './styles';

const MainFeatures = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Text className={classes.textDiscover}>{t('discover_the_core_functionalities')}</Text>

      <GridMainFeature1
        xsGridLeft={12}
        mdGridLeft={8}
        xsGridRight={12}
        mdGridRight={4}
        src1={MainFeature1}
        textContent={t('inventory_management')}
        textSubContent={t('manage_your_art_gallery_professionally')}
        textButton={t('start_exploring')}
        textSubContent2={t('artwork_artist_management_advanced')}
        textSubContent3={t('check_activity_log_and_turn_data')}
        textSubContent4={t('mobile_app')}
      />

      <GridMainFeature2
        xsGridLeft={12}
        mdGridLeft={4}
        xsGridRight={12}
        mdGridRight={8}
        src1={MainFeature4}
        textContent={t('issued_certificates_and_evidences')}
        textSubContent={t('propose_various_solutions_multiple')}
        textButton={t('start_exploring')}
        gridStyle={classes.grid}
        textSubContent2={t('certificate_authenticity_artworks')}
        textSubContent3={t('easy_management_and_tracking')}
      />

      <GridMainFeature1
        xsGridLeft={12}
        mdGridLeft={8}
        xsGridRight={12}
        mdGridRight={4}
        src1={MainFeature8}
        textContent={t('artistic_showcase')}
        textSubContent={t('share_the_private_gallery')}
        textButton={t('start_exploring')}
        gridStyle={classes.grid}
        textSubContent2={t('provide_personal_pages_for_users_showcase')}
        textSubContent3={t('provide_tools_to_curate_collection')}
        textSubContent4={t('ensure_data_security_and_controlled_sharing')}
      />

      <GridMainFeature2
        xsGridLeft={12}
        mdGridLeft={4}
        xsGridRight={12}
        mdGridRight={8}
        src1={MainFeature10}
        textContent={t('relationship_management')}
        textSubContent={t('build_sustainable_relationship')}
        textButton={t('start_exploring')}
        gridStyle={classes.grid}
        textSubContent2={t('chatting_tool')}
        textSubContent3={t('detailed_contact_profiles_for_storing')}
        textSubContent4={t('sales_opportunity_tracking')}
      />
    </div>
  );
};

export default MainFeatures;
