import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Breadcrumbs from 'commons/Breadcrumbs';
import TabPanel from 'commons/TabPanel';
import Text from 'commons/Text';
import Routes from 'utils/Route';

import ChangePassword from './components/ChangePassword';
import MyAccountForm from './components/MyAccountForm';
import MyAccountNav from './components/MyAccountNav';
import useStyles from './styles';
import { userSchema } from './validations';

const MyAccount = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = Number(searchParams.get('tab')) || 1;

  const userFormState = useForm({
    values: {
      legalName: user?.legal_name || '',
      nickname: user?.name || '',
      placeOfBirth: user?.place_of_birth || '',
      yearOfBirth: user?.year_of_birth || '',
      email: user?.email || '',
      phoneNumber: user?.phone_number || '',
      avatar: user?.avatar,
      avatarUploadFile: null,
    },
    mode: 'onChange',
    resolver: yupResolver(userSchema),
  });

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.MyAccount,
      text: t('my_account'),
      active: true,
    },
  ];

  const handleMenuItemChange = (item) => {
    searchParams.set('tab', item?.tabValue);
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.wrapMyAccountPage}>
      <Container>
        <Breadcrumbs items={breadCrumbs} />
        <Text
          variant="h1"
          type="2xl"
          title
          className={classes.heading}
        >
          {t('my_account')}
        </Text>

        <Box sx={{ pt: 3 }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              lg={3}
            >
              <MyAccountNav
                user={user}
                userFormState={userFormState}
                onNavItemClick={handleMenuItemChange}
                tab={tab}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={9}
            >
              <TabPanel
                value={tab}
                index={1}
              >
                <MyAccountForm
                  userFormState={userFormState}
                  user={user}
                />
              </TabPanel>

              <TabPanel
                value={tab}
                index={2}
              >
                <ChangePassword />
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default MyAccount;
