import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import OneSignal from 'react-onesignal';

import Text from 'commons/Text';
import API from 'configs/API';
import { ReactComponent as BellIcon } from 'images/bell.svg';
import { axiosPost } from 'utils/apis/axios';

import useStyles from '../styles';
import NotificationPopover from './NotificationPopover';

const AppNotification = () => {
  const classes = useStyles();

  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRinging, setIsRinging] = useState(false);
  const { data: notificationNumber } = useQuery({
    queryKey: [API.AUTH.NOTIFICATION_NUMBER],
  });

  const handleClickIconNotification = async (e) => {
    setAnchorEl(e?.currentTarget);
    setIsRinging(false);
    await axiosPost(API.AUTH.SET_LAST_SEEN);
    queryClient.invalidateQueries({ queryKey: [API.AUTH.NOTIFICATION_NUMBER] });
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    function foregroundWillDisplayListener() {
      queryClient.invalidateQueries({
        queryKey: [API.AUTH.NOTIFICATION_NUMBER],
      });
      queryClient.invalidateQueries({
        queryKey: [API.AUTH.NOTIFICATION],
      });
      setIsRinging(true);
    }

    OneSignal.Notifications.addEventListener(
      'foregroundWillDisplay',
      foregroundWillDisplayListener,
    );

    return () => {
      OneSignal.Notifications.removeEventListener(
        'foregroundWillDisplay',
        foregroundWillDisplayListener,
      );
    };
  }, [queryClient]);

  return (
    <div className={classes.wrapIconMessage}>
      <div className={`${classes.iconWrapper} ${isRinging ? classes.ringAnimation : ''}`}>
        <BellIcon onClick={handleClickIconNotification} />
      </div>
      {!!notificationNumber?.unseen && (
        <Text
          className={classes.messageCounterNumber}
          type="xs"
        >
          {notificationNumber?.unseen}
        </Text>
      )}
      <NotificationPopover
        anchorEl={anchorEl}
        onClose={handleClosePopOver}
      />
    </div>
  );
};

export default AppNotification;
