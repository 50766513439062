import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';

const notify = (type, msg, extra = {}) => {
  toast[type](msg, {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    pauseOnFocusLoss: false,
    toastId: msg,
    ...extra,
  });
};

export const ToastTopHelper = {
  success: (msg, extra) => {
    notify('success', msg, extra);
  },
  error: (msg, extra) => {
    notify('error', msg, extra);
  },
};

export const setAxiosDefaultAuthToken = (token) => {
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common.Authorization = `Token ${token}`;
  reactLocalStorage.set('token', token);
};

export const removeAxiosDefaultAuthToken = () => {
  delete axios.defaults.headers.common.Accept;
  delete axios.defaults.headers.common.Authorization;
  reactLocalStorage.remove('token');
  reactLocalStorage.clear();
};

export const initData = ({ token }) => {
  setAxiosDefaultAuthToken(token);
};

export const formatCurrency = (price) => {
  if (!price) {
    return null;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'VND',
  });

  return formatter.format(price);
};

export const capitalizeFirstLetter = (string) => {
  const newString = string.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const capitalizeLetterFullName = (inputString) => {
  return inputString
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatDateString = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  return date
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    .toUpperCase();
};

export const getTextEdition = (t, edition, total_edition) => {
  if (!edition) {
    return '--';
  }
  if (total_edition === 1) {
    return t('unique');
  }
  return `${edition}/${total_edition}`;
};

export const setAxiosDefaultLanguage = (language) => {
  axios.defaults.headers.common['Accept-Language'] = language;
};

export const generateStrongPassword = (length = 12) => {
  const lowercaseCharset = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseCharset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberCharset = '0123456789';
  const specialCharset = '!@#$%^&*()_+{}:"<>?[];\',./`~';
  let password = '';
  password += uppercaseCharset.charAt(Math.floor(Math.random() * uppercaseCharset.length));
  password += lowercaseCharset.charAt(Math.floor(Math.random() * lowercaseCharset.length));
  password += numberCharset.charAt(Math.floor(Math.random() * numberCharset.length));
  password += specialCharset.charAt(Math.floor(Math.random() * specialCharset.length));
  for (let i = 4; i < length; i++) {
    const charset = lowercaseCharset + uppercaseCharset + numberCharset;
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset.charAt(randomIndex);
  }
  password = password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');

  return password;
};

export const isUserInformationVerified = (user) => {
  return (
    user?.legal_name &&
    user?.name &&
    user?.phone_number &&
    user?.email &&
    user?.place_of_birth &&
    user?.year_of_birth
  );
};

export const formatArtworkTitle = (artwork) => {
  if (!artwork) return '';

  const { title, year_created, period_created } = artwork;

  if (title) {
    if (year_created || period_created) {
      return `${title}, ${year_created || period_created}`;
    }
    return title;
  }

  return year_created || period_created || '';
};

export const useQueryParameters = () => {
  return new URLSearchParams(useLocation().search);
};
