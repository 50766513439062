import { Link } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { ReactComponent as IconGlobal } from 'images/icon_global.svg';

import useStyles from './styles';

const ArtistWebsites = ({ listWebsite }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isVisibilityWebsites = listWebsite?.length;
  const maxLines = 1;
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (!isVisibilityWebsites) {
    return null;
  }

  const visibleExhibitions = showMore ? listWebsite : listWebsite.slice(0, maxLines);

  return (
    <div>
      {visibleExhibitions.map((item, index) => (
        <div
          key={index}
          className={classes.wrapItemWebsite}
        >
          <div className={classes.iconGlobal}>
            <IconGlobal />
          </div>
          <Link
            key={index}
            href={item}
            target="_blank"
            underline="always"
            className={classes.linkItem}
          >
            {item}
          </Link>
        </div>
      ))}
      {listWebsite.length > maxLines && (
        <div className={classes.textSeeMore}>
          <Text onClick={toggleShowMore}>{showMore ? t('show_less') : t('see_more')}</Text>
        </div>
      )}
    </div>
  );
};

export default ArtistWebsites;
