import { Box, MenuItem, MenuList } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Text from 'commons/Text';
import useScrollSpy from 'pages/EditArtistProfile/hooks/useScrollspy';

import useStyles from './styles';

const ArtworkMenuList = ({ groups }) => {
  const classes = useStyles();
  const [listMenu, setListMenu] = useState([]);
  const activeMenu = useScrollSpy(listMenu, 750);

  const handleItemClick = (index) => {
    const element = listMenu[index];
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useEffect(() => {
    const elements = document.querySelectorAll('[name=artwork-group]');
    const elementsArray = Array.from(elements);

    setListMenu(elementsArray);
  }, []);

  return (
    <Box className={classes.wrapper}>
      <MenuList>
        {groups?.map((group, index) => (
          <MenuItem
            key={group?.id}
            onClick={() => handleItemClick(index)}
            selected={activeMenu ? group?.id === parseInt(activeMenu?.getAttribute('id')) : false}
          >
            <div className={classes.menuItemWrap}>
              <Text fontWeightMedium>{group?.title}</Text>
              <Text type="sm">{group?.artworks?.length} artworks</Text>
            </div>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default ArtworkMenuList;
