import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useParams, useSearchParams } from 'react-router-dom';

import CollectionItem from 'commons/Collection/CollectionItem';
import PaginationCustom from 'commons/PaginationCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';
import Routes from 'utils/Route';
import { useQueryParameters } from 'utils/utils';

import TabArtworkHeader from '../TabArtworkHeader';
import useStyles from './styles';

const ArtworkCollection = ({ isOwner }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const query = useQueryParameters();
  const searchValue = query.get('search');

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const objectParamsFromSearchParams = Object.fromEntries(searchParams);
  const params = {
    ...objectParamsFromSearchParams,
    user_uuid: id,
  };

  const { data, isPending } = useQuery({
    queryKey: [API.ARTWORK.LIST_GALLERY_COLLECTION, params],
  });

  const { page, totalPage, onPageChange } = usePagination(data?.count, data?.page_size);

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const listCollection = data?.results;

  if (isPending) {
    return;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <TabArtworkHeader
        placeholder={t('search_by_title_collection_name')}
        isOwner={isOwner}
      />

      <Grid
        container
        spacing={5}
      >
        {listCollection?.map((collection, index) => (
          <Grid
            key={index}
            item
            xs={12}
            lg={3}
          >
            <CollectionItem
              collection={collection}
              isOwner={isOwner}
              redirectTo={{
                pathname: Routes.GalleryDetail(id),
                search: createSearchParams({ tab: '2' }).toString(),
              }}
            />
          </Grid>
        ))}
      </Grid>

      {searchValue && data?.count === 0 && (
        <div className={classes.wrapTextNoResult}>
          <Text>{t('no_results_that_match_your_search')}</Text>
          <Text>{t('please_try_adjust_your_keywords')}</Text>
        </div>
      )}

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default ArtworkCollection;
