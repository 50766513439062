import { Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import ArtistPage from 'commons/ArtistPage';
import TabPanel from 'commons/TabPanel';
import API from 'configs/API';
import useRole from 'hooks/useRole';

import ArtworkCollection from '../ArtworkCollection';
import GalleryArtist from '../GalleryArtist';
import GalleryArtwork from '../GalleryArtwork';
import GalleryExhibition from '../GalleryExhibition';
import SpeedDialNavigation from '../SpeedDialNavigation';
import useStyles from './styles';

const GalleryTabContent = ({ userProfile, roleUser }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const user = useSelector((state) => state.auth.account.user);
  const isOwner = user?.uuid === id;
  const [searchParams, setSearchParams] = useSearchParams();
  const numberTabValue = isOwner || token ? 1 : 4;
  const tabValue = Number(searchParams.get('tab') || numberTabValue);
  const { isCollectorOrGalleryOwner, isArtist } = useRole(roleUser);

  const { data: artworksCount } = useQuery({
    queryKey: [API.ARTWORK.ARTWORK_COUNT, { owner_uuid: id }],
  });
  const { data: collectionsCount } = useQuery({
    queryKey: [API.ARTWORK.COLLECTION_COUNT, { owner_uuid: id }],
  });
  const { data: exhibitionCount } = useQuery({
    queryKey: [API.ARTWORK.EXHIBITION_COUNT, { owner_uuid: id }],
    enabled: isArtist,
  });
  const { data: artistCount } = useQuery({
    queryKey: [API.ARTWORK.ARTIST_COUNT],
    enabled: isCollectorOrGalleryOwner && isOwner,
  });

  const handleTabChange = (e, newValue) => {
    searchParams.set('tab', newValue);
    searchParams.delete('search');
    searchParams.delete('page');
    searchParams.delete('ordering');
    setSearchParams(searchParams, { replace: true });
  };

  const getLabel = ({ text, number }) => {
    return `${text} ${number}`;
  };

  const tokenExpiresAt = new Date().getTime() + 30 * 60 * 1000;
  const currentTime = new Date().getTime();
  const timeLeft = tokenExpiresAt - currentTime;

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => {
        window.location.reload();
      }, timeLeft);
    }
  }, [timeLeft]);

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        {(isOwner || token) && (
          <Tab
            label={getLabel({
              text: t('all_works'),
              number: artworksCount || '',
            })}
            value={1}
            className={classes.tab}
          />
        )}

        {isCollectorOrGalleryOwner && (
          <Tab
            label={getLabel({
              text: t('artists'),
              number: artistCount || '',
            })}
            value={2}
            className={classes.tab}
          />
        )}

        {(isOwner || token) && (
          <Tab
            label={getLabel({
              text: t('collection'),
              number: collectionsCount || '',
            })}
            value={3}
            className={classes.tab}
          />
        )}

        {isArtist && (
          <Tab
            label={t('artist_profile')}
            value={4}
            className={classes.tab}
          />
        )}

        <Tab
          label={getLabel({
            text: t('exhibitions'),
            number: exhibitionCount || '',
          })}
          value={5}
          className={classes.tab}
        />
      </Tabs>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <GalleryArtwork
          token={token}
          isOwner={isOwner}
        />
      </TabPanel>

      {isCollectorOrGalleryOwner && (
        <TabPanel
          value={tabValue}
          index={2}
        >
          <GalleryArtist tokenParam={token} />
        </TabPanel>
      )}

      <TabPanel
        value={tabValue}
        index={3}
      >
        <ArtworkCollection isOwner={isOwner} />
      </TabPanel>

      {isArtist && (
        <TabPanel
          value={tabValue}
          index={4}
        >
          <ArtistPage
            userProfile={userProfile}
            artistUuid={id}
          />
        </TabPanel>
      )}

      <TabPanel
        value={tabValue}
        index={5}
      >
        <GalleryExhibition isOwner={isOwner} />
      </TabPanel>

      {isOwner && <SpeedDialNavigation />}
    </>
  );
};

export default GalleryTabContent;
