import { Popover } from '@mui/material';
import React from 'react';

import useStyles from '../styles';
import NotificationHeader from './NotificationHeader';
import NotificationList from './NotificationList';

const NotificationPopover = ({ anchorEl, onClose }) => {
  const classes = useStyles();

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onClose={onClose}
      onClick={onClose}
      className={classes.popover}
    >
      <NotificationHeader />
      <NotificationList />
    </Popover>
  );
};

export default NotificationPopover;
