import React from 'react';
import { useSelector } from 'react-redux';

import useStyles from '../styles';
import AppNotification from './AppNotification';
import ButtonGroup from './ButtonGroup';
import MenuLanguage from './MenuLanguage';
import MenuProfile from './MenuProfile';

const HeaderRight = ({ isPageLogin }) => {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <div className={classes.wrapGroupItem}>
      <MenuLanguage />

      {!isPageLogin && (
        <>
          {isLoggedIn ? (
            <>
              <AppNotification />
              {/* <MessageNotification /> */}
              <MenuProfile />
            </>
          ) : (
            <ButtonGroup />
          )}
        </>
      )}
    </div>
  );
};

export default HeaderRight;
