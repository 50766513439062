import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapFormLabel: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  formLabel: {
    '&.MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },
}));

export default useStyles;
