import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { ReactComponent as IconButtonRadio } from 'images/icon_button_radio.svg';

import useStyles from '../styles';

const Publications = ({ listPublication }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isVisibilityPublications = listPublication?.length;
  const maxLines = 3;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  if (!isVisibilityPublications) {
    return null;
  }

  const visibleEPublications = showMore ? listPublication : listPublication.slice(0, maxLines);

  return (
    <Box className={classes.boxWrapItemsProfile}>
      <div className={classes.iconButtonRadio}>
        <IconButtonRadio />
      </div>
      <div className={classes.wrapItemProfile}>
        <Box className={classes.boxItemProfile}>
          <Text
            type="xl"
            fontWeightBold
          >
            {t('publications')}
          </Text>
          <div className={classes.wrapListProfile}>
            {visibleEPublications.map((item, index) => (
              <div
                key={index}
                className={classes.wrapListItemProfile}
              >
                <div className={classes.textYear}>{item?.year || 'N/A'}</div>
                <div className={classes.wrapDescriptionAndItem}>
                  <Text className={classes.textDescription}>{item?.description}</Text>
                </div>
              </div>
            ))}
          </div>
          {listPublication.length > maxLines && (
            <div className={classes.textSeeMore}>
              <Text
                bold
                onClick={toggleShowMore}
              >
                {showMore ? t('show_less') : t('see_more')}
              </Text>
            </div>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default Publications;
