import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import React from 'react';

import useStyles from './styles';

const CheckBoxCustom = ({
  checked,
  onChange,
  textCheckBox,
  error,
  helperText,
  wrapCheckBoxStyle,
  labelText,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormGroup className={wrapCheckBoxStyle}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            className={classes.checkbox}
            inputProps={{ 'aria-label': textCheckBox }}
            {...props}
          />
        }
        label={<span className={labelText}>{textCheckBox}</span>}
      />

      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormGroup>
  );
};

export default CheckBoxCustom;
