import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import PaginationCustom from 'commons/PaginationCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';
import HeaderListArtist from 'pages/Gallery/components/HeaderListArtist';
import Routes from 'utils/Route';
import { useQueryParameters } from 'utils/utils';

import useStyles from './styles';

const TabArtists = ({ owner }) => {
  const { id } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const query = useQueryParameters();
  const searchValue = query.get('search');
  const navigate = useNavigate();

  const objectParamsFromSearchParams = Object.fromEntries(searchParams);
  const params = {
    ...objectParamsFromSearchParams,
  };

  const { data: dataListArtist, isPending } = useQuery({
    queryKey: [API.ARTWORK.LIST_ARTIST_NAME_FOR_EXHIBITION(id), params],
  });

  const handleRouter = (artist) => {
    const url = Routes.UserArtistArtwork(owner?.uuid, artist?.uuid || artist?.id);
    navigate(url);
  };

  const { page, totalPage, onPageChange } = usePagination(
    dataListArtist?.count,
    dataListArtist?.page_size,
  );

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const listArtist = dataListArtist?.results || [];

  if (isPending) {
    return;
  }

  return (
    <Box>
      <HeaderListArtist placeholder={t('search_artist_name')} />
      <div>
        <Grid
          container
          spacing={2}
          sx={{ mt: 2 }}
        >
          {listArtist.map((artist, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={index}
              sx={{ mb: 2 }}
            >
              <div onClick={() => handleRouter(artist)}>
                <Text fontWeightMedium>
                  {artist?.artist_name}{' '}
                  {artist?.year_of_birth ? `(B.${artist.year_of_birth})` : '(N/A)'}
                </Text>
                <Text
                  type="sm"
                  className={classes.textArtworkCount}
                >
                  {artist?.artwork_count} {t('artworks')}
                </Text>
              </div>
            </Grid>
          ))}
        </Grid>

        {searchValue && dataListArtist?.count === 0 && (
          <div className={classes.wrapTextNoResult}>
            <Text>{t('no_results_that_match_your_search')}</Text>
            <Text>{t('please_try_adjust_your_keywords')}</Text>
          </div>
        )}

        {!!totalPage && (
          <PaginationCustom
            paginationStyle={classes.pagination}
            count={totalPage}
            page={page}
            onChange={handlePageChange}
          />
        )}
      </div>
    </Box>
  );
};

export default TabArtists;
