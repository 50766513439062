import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import API from 'configs/API';
import { TESTID } from 'configs/Constant';
import SectionWrapper from 'pages/EditArtistProfile/commons/SectionWrapper';
import { axiosPatch } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import ArtistPlacement from './components/ArtistPlacement';
import Membership from './components/Membership';
import TrainingBackground from './components/TrainingBackground';
import useStyles from './styles';

const ArtistBiography = ({ userProfile, onUpdateUserProfile }) => {
  const [membership, setMembership] = useState([{ year: '', description: '' }]);
  const [trainingBackground, setTrainingBackground] = useState([
    { year: '', to_year: '', description: '', isChecked: false },
  ]);
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [placeOfBirth, setPleaceOfBirth] = useState('');

  const nonBlankData = (array) => {
    return array.filter((data) => data?.description.trim() !== '');
  };
  const nonBlankMembership = nonBlankData(membership);
  const nonBlankTrainingBackground = nonBlankData(trainingBackground);

  const errorYearOfBirth = () => {
    const currentYear = new Date().getFullYear();
    const year = parseInt(yearOfBirth, 10);
    if (year > currentYear || year <= 0) {
      return t('please_enter_correct_year');
    }

    return false;
  };

  const errorYear = (data) => {
    const currentYear = new Date().getFullYear();
    const year = parseInt(data?.year, 10);
    if (year > currentYear || year <= 0) {
      return t('please_enter_correct_year');
    }

    return false;
  };

  const classes = useStyles();
  const { t } = useTranslation();

  const validate = () => {
    if (errorYearOfBirth()) {
      return false;
    }

    const validTrainingBackground = nonBlankTrainingBackground.every((data) => !errorYear(data));
    if (!validTrainingBackground) {
      return false;
    }

    const validMembership = nonBlankMembership.every((data) => !errorYear(data));

    if (!validMembership) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    const updateData = {
      membership: nonBlankMembership,
      training_background: nonBlankTrainingBackground,
      year_of_birth: yearOfBirth,
      place_of_birth: placeOfBirth,
    };

    const { success } = await axiosPatch(API.AUTH.PROFILE_INFO, updateData);

    if (success) {
      ToastTopHelper.success(t('update_successfully'));
      onUpdateUserProfile();
    }
  };

  useEffect(() => {
    const userProfileData = Object.keys(userProfile).length;
    if (userProfileData) {
      userProfile?.training_background?.length &&
        setTrainingBackground(userProfile?.training_background);
      userProfile?.membership?.length && setMembership(userProfile?.membership);

      setYearOfBirth(userProfile?.year_of_birth);
      setPleaceOfBirth(userProfile?.place_of_birth);
    }
  }, [userProfile]);

  return (
    <SectionWrapper heading={t('artist_biography')}>
      <ArtistPlacement
        yearOfBirth={yearOfBirth}
        setYearOfBirth={setYearOfBirth}
        placeOfBirth={placeOfBirth}
        setPleaceOfBirth={setPleaceOfBirth}
        errorYearOfBirth={errorYearOfBirth}
      />
      <TrainingBackground
        trainingBackground={trainingBackground}
        setTrainingBackground={setTrainingBackground}
        errorYear={errorYear}
      />
      <Membership
        membership={membership}
        setMembership={setMembership}
        errorYear={errorYear}
      />

      <Button
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleSubmit}
        className={classes.btnSubmit}
        disabled={!validate()}
        testid={`${TESTID.BUTTON}_SUBMIT`}
      >
        {t('save')}
      </Button>
    </SectionWrapper>
  );
};

export default ArtistBiography;
