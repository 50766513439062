import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { ReactComponent as IconButtonRadio } from 'images/icon_button_radio.svg';

import useStyles from '../styles';

const Exhibitions = ({ listExhibition, textExhibition }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isVisibilityExhibitions = listExhibition?.length;
  const maxLines = 3;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (!isVisibilityExhibitions) {
    return null;
  }

  const visibleExhibitions = showMore ? listExhibition : listExhibition.slice(0, maxLines);

  return (
    <Box className={classes.boxWrapItemsProfile}>
      <div className={classes.iconButtonRadio}>
        <IconButtonRadio />
      </div>
      <div className={classes.wrapItemProfile}>
        <Box className={classes.boxItemProfile}>
          <Text
            type="xl"
            fontWeightBold
          >
            {textExhibition}
          </Text>
          <div className={classes.wrapListProfile}>
            {visibleExhibitions.map((item, index) => (
              <div
                key={index}
                className={classes.wrapListItemProfile}
              >
                <div className={classes.textYear}>
                  {item?.year
                    ? item?.to_year
                      ? `${item.year} - ${item.to_year}`
                      : item.year
                    : item?.to_year
                      ? item.to_year
                      : 'N/A'}
                </div>
                <div className={classes.wrapDescriptionAndItem}>
                  {!item?.exhibition_link && (
                    <Text className={classes.textDescriptionExhibition}>{item?.description}</Text>
                  )}
                  {item?.exhibition_link && (
                    <a
                      key={index}
                      href={item.exhibition_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.linkItem}
                    >
                      {item.description}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
          {listExhibition.length > maxLines && (
            <div className={classes.textSeeMore}>
              <Text
                bold
                onClick={toggleShowMore}
              >
                {showMore ? t('show_less') : t('see_more')}
              </Text>
            </div>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default Exhibitions;
