import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import TextArea from 'commons/TextArea';
import Colors from 'configs/Colors';
import useUserProfile from 'hooks/useUserProfile';
import SectionWrapper from 'pages/EditArtistProfile/commons/SectionWrapper';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const ArtistStatement = ({ userProfile, onUpdateUserProfile }) => {
  const [statement, setSatement] = useState();
  const [error, setError] = useState();

  const { updateUserProfile } = useUserProfile();

  const classes = useStyles();
  const { t } = useTranslation();

  const handleStatementChange = (e) => {
    const { value } = e.target;
    setSatement(value);
    setError(value?.length >= 1001);
  };

  const handleUpdateSuccess = () => {
    onUpdateUserProfile();
    ToastTopHelper.success(t('update_successfully'));
  };

  const validate = () => {
    const disabled = JSON.stringify(userProfile?.introduction) === JSON.stringify(statement);
    if (disabled) {
      return false;
    }
    if (error) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    const updateData = {
      introduction: statement,
    };

    updateUserProfile(updateData, handleUpdateSuccess);
  };

  useEffect(() => {
    if (userProfile) {
      setSatement(userProfile?.introduction);
    }
  }, [userProfile]);

  return (
    <SectionWrapper heading={t('artist_statements')}>
      <Text
        mt={16}
        color={Colors.Grey8}
      >
        {t('text_description_artist_statement')}
      </Text>

      <Text mt={16}>{t('description')}</Text>

      <TextArea
        value={statement}
        onChange={handleStatementChange}
        maxLength={1001}
        textMaxLength={1000}
        placeholder={t('place_holder_statement')}
        helperText={error && t('text_max_length_error', { length: 1000 })}
        error={error}
      />

      <Button
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleSubmit}
        disabled={!validate()}
        className={classes.btnSubmit}
      >
        {t('save')}
      </Button>
    </SectionWrapper>
  );
};

export default ArtistStatement;
