import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isFieldValid, isValidURL, isValidYear } from 'utils/Validation';
import { axiosPatch } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

export const useDialogEditArrtistProfile = (
  editedYear,
  editedToYear,
  editedDescription,
  editedExhibitionLink,
  setEditYear,
  setEditToYear,
  setEditDescription,
  setEditedIsPublic,
  setIsShowDialogEdit,
  setEditedExhibitionLink,
  selectedExhibition,
  editedIsPublic,
  getListExhibition,
) => {
  const { t } = useTranslation();
  const [wrongExhibitionLink, setWrongExhibitionLink] = useState(false);
  const [wrongYear, setWrongYear] = useState(false);
  const [wrongToYear, setWrongToYear] = useState(false);

  const isYearValid = isFieldValid(editedYear, wrongYear);

  const isFormValid =
    isYearValid && editedDescription && (!wrongExhibitionLink || !editedExhibitionLink);

  const handleChangeYear = (e) => {
    const value = e.target.value;
    setWrongYear(!isValidYear(value));
    if (value === '') {
      setWrongYear(false);
    }
    setEditYear(value);
  };

  const handleChangeToYear = (e) => {
    const value = e.target.value;
    setWrongToYear(!isValidYear(value));
    if (value === '') {
      setWrongToYear(false);
    }
    setEditToYear(value);
  };

  const handleChangeDescription = (e) => {
    const value = e.target.value;
    if (value?.length > 200) {
      return;
    }
    setEditDescription(value);
  };

  const handleSwitchChange = (e) => {
    const isHidePrice = e.target.checked;
    setEditedIsPublic(isHidePrice);
  };

  const handleCancelExhibition = () => {
    setIsShowDialogEdit(false);
    setWrongExhibitionLink(false);
    setWrongYear(false);
    setWrongToYear(false);
  };

  const handleChangeLink = (e) => {
    const url = e.target.value;
    setWrongExhibitionLink(!isValidURL(url));
    setEditedExhibitionLink(url);
  };

  const handleSubmitExhibition = async (url) => {
    if (editedYear && editedToYear && parseInt(editedYear) > parseInt(editedToYear)) {
      return ToastTopHelper.error(t('to_year_cannot_earlier_than_year'));
    }
    const { success } = await axiosPatch(url, {
      year: editedYear || null,
      to_year: editedToYear || null,
      description: editedDescription,
      is_public: editedIsPublic,
      exhibition_link: editedExhibitionLink,
    });
    if (success) {
      getListExhibition();
      setIsShowDialogEdit(false);
      ToastTopHelper.success(t('edit_success'));
    }
  };

  return {
    wrongExhibitionLink,
    wrongYear,
    wrongToYear,
    isFormValid,
    handleChangeYear,
    handleChangeToYear,
    handleChangeDescription,
    handleSwitchChange,
    handleCancelExhibition,
    handleChangeLink,
    handleSubmitExhibition,
  };
};

export default useDialogEditArrtistProfile;
