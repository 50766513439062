import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

import PrivateRoute from 'commons/PrivateRoute';
import ManageStorage from 'commons/PrivateRoute/ManageStorage';
import PublicRoute from 'commons/PublicRoute';
import API from 'configs/API';
import useUserAccount from 'hooks/useUserAccount';
import AboutUs from 'pages/AboutUs';
import ArtistProfile from 'pages/ArtistProfile';
import Artists from 'pages/Artists';
import ArtworkDetail from 'pages/ArtworkDetail';
import ChooseAccountType from 'pages/ChooseAccountType';
import CollectionDetail from 'pages/CollectionDetail';
import CreateCertificate from 'pages/CreateCertificate';
import CreateCollection from 'pages/CreateCollection';
import CreateExhibition from 'pages/CreateExhibition';
import EditArtistProfile from 'pages/EditArtistProfile';
import EditArtworkRaw from 'pages/EditArtwork';
import EditCertificateRequest from 'pages/EditCertificateRequest';
import EditCollection from 'pages/EditCollection';
import EditExhibition from 'pages/EditExhibition';
import ExhibitionDetail from 'pages/ExhibitionDetail';
import Gallery from 'pages/Gallery';
import Login from 'pages/Login';
import ManageArtwork from 'pages/ManageArtwork';
import ManageCertificate from 'pages/ManageCertificate';
import ManageCollection from 'pages/ManageCollection';
import ManageEdition from 'pages/ManageEdition';
import MyAccount from 'pages/MyAccount';
import PageNotFound from 'pages/PageNotFound';
import RequestCertificate from 'pages/RequestCertificate';
import ReviewCertificateRequest from 'pages/ReviewCertificateRequest';
import ReviewTagRequest from 'pages/ReviewTagRequest';
import UploadArtwork from 'pages/UploadArtwork';
import UserArtistArtwork from 'pages/UserArtistArtwork';
import VerifyInformation from 'pages/VerifyInformation';
import ViewCertificate from 'pages/ViewCertificate';
import Path from 'utils/Route';
import { axiosPost } from 'utils/apis/axios';
import { setAxiosDefaultAuthToken, setAxiosDefaultLanguage } from 'utils/utils';

const AppRouter = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const language = useSelector((state) => state?.language?.currentLanguage);

  const [isInitOneSignal, setIsInitOneSignal] = useState(false);

  const { getUserAccount } = useUserAccount();

  isLoggedIn && setAxiosDefaultAuthToken(reactLocalStorage.get('token'));

  useEffect(() => {
    const isStaging = process.env.REACT_APP_ENVIRONMENT === 'STAGING';
    if (isStaging) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }, []);

  const initializeOneSignal = useCallback(async () => {
    if (typeof window === 'undefined' || isInitOneSignal) {
      return;
    }

    if (!isLoggedIn) {
      return;
    }

    try {
      await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        notifyButton: {
          enable: true,
        },
      });
      setIsInitOneSignal(true);
    } catch (err) {
      console.error('Error initializing OneSignal:', err);
    }
  }, [isInitOneSignal, isLoggedIn]);

  useEffect(() => {
    initializeOneSignal();
  }, [initializeOneSignal]);

  useEffect(() => {
    if (!isInitOneSignal) {
      return;
    }

    if (!isLoggedIn) {
      return;
    }

    const registerUserSignalId = async () => {
      OneSignal.User.PushSubscription.addEventListener('change', async (change) => {
        if (change.current.id) {
          const subscriptionId = change.current.id;
          await axiosPost(API.AUTH.REGISTER_SIGNAL_ID, { signal_id: subscriptionId });
        }
      });

      const signal_id = OneSignal.User.PushSubscription.id;

      if (signal_id) {
        await axiosPost(API.AUTH.REGISTER_SIGNAL_ID, { signal_id });
      }
    };

    registerUserSignalId();
  }, [isInitOneSignal, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const requestNotiPermission = async () => {
      await OneSignal.Notifications.requestPermission();
    };
    requestNotiPermission();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    const currentLanguage = language || 'en';
    setAxiosDefaultLanguage(currentLanguage);
    i18n.changeLanguage(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path={Path.Login}
            exact
            element={<Login />}
          />
          {/* <Route
          path={Path.SignUp}
          exact
          element={<SignUp />}
        /> */}
          <Route
            path={Path.ChooseAccountType}
            exact
            element={<ChooseAccountType />}
          />

          <Route
            path={Path.VerifyInformation}
            exact
            element={<VerifyInformation />}
          />

          <Route
            path={Path.ViewCertificate()}
            exact
            element={<ViewCertificate />}
          />

          <Route element={<PublicRoute />}>
            <Route
              path={Path.Home}
              exact
              element={<Artists />}
            />
            <Route
              path={Path.ArtworkDetail()}
              exact
              element={<ArtworkDetail />}
            />
            <Route
              path={Path.GalleryDetail()}
              exact
              element={<Gallery />}
            />
            <Route
              path={Path.AboutUs}
              exact
              element={<AboutUs />}
            />
            <Route
              path={Path.CollectionDetail()}
              exact
              element={<CollectionDetail />}
            />
            <Route
              path={Path.ExhibitionDetail()}
              exact
              element={<ExhibitionDetail />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path={Path.MyAccount}
              exact
              element={<MyAccount />}
            />
            <Route
              path={Path.UploadArtwork}
              exact
              element={<UploadArtwork />}
            />
            <Route
              path={Path.EditArtwork()}
              exact
              element={<EditArtworkRaw />}
            />
            <Route
              path={Path.ArtistProfile()}
              exact
              element={<ArtistProfile />}
            />
            <Route
              path={Path.EditArtistProfile}
              exact
              element={<EditArtistProfile />}
            />
            <Route
              path={Path.RequestCertificateId()}
              exact
              element={<RequestCertificate />}
            />

            <Route
              path={Path.ReviewCertificateRequest()}
              exact
              element={<ReviewCertificateRequest />}
            />
            <Route
              path={Path.ReviewTagRequest()}
              exact
              element={<ReviewTagRequest />}
            />
            <Route
              path={Path.EditCertificateRequest()}
              exact
              element={<EditCertificateRequest />}
            />
            <Route
              path={Path.CreateCertificate()}
              exact
              element={<CreateCertificate />}
            />
            <Route
              path={Path.CreateCollection}
              exact
              element={<CreateCollection />}
            />
            <Route
              path={Path.CreateExhibition}
              exact
              element={<CreateExhibition />}
            />
            <Route
              path={Path.EditExhibition()}
              exact
              element={<EditExhibition />}
            />
            <Route
              path={Path.EditCollection()}
              exact
              element={<EditCollection />}
            />
            {/* <Route
              path={Path.Chat}
              exact
              element={<Chat />}
            /> */}
            <Route
              path={Path.UserArtistArtwork()}
              exact
              element={<UserArtistArtwork />}
            />

            <Route element={<ManageStorage />}>
              <Route
                path={Path.ManageArtwork}
                exact
                element={<ManageArtwork />}
              />
              <Route
                path={Path.ManageEdition}
                exact
                element={<ManageEdition />}
              />
              <Route
                path={Path.ManageCertificate}
                exact
                element={<ManageCertificate />}
              />
              <Route
                path={Path.ManageCollection}
                exact
                element={<ManageCollection />}
              />
            </Route>
          </Route>

          <Route
            path="*"
            element={<PageNotFound />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default AppRouter;
