import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import useUserProfile from 'hooks/useUserProfile';
import { ReactComponent as PenEdit } from 'images/pen_edit.svg';
import { ToastTopHelper } from 'utils/utils';

import RenderStatement from './components/RenderStatement';
import useStyles from './styles';

const BoxStatement = ({
  statement,
  defaultStatement,
  isOwner,
  setStatement,
  setDefaultStatement,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isEditState, setIsEditState] = useState(false);
  const showSuccessToastRef = useRef(true);
  const { updateUserProfile } = useUserProfile();

  const handleUpdateStatementSuccess = (data) => {
    setDefaultStatement(data?.introduction);
    setIsEditState(false);
    if (showSuccessToastRef.current) {
      ToastTopHelper.success(t('update_successfully'));
    }
  };

  const handleUpdateAccountStatement = () => {
    const isStatementEmpty = !statement || statement.trim()?.length === 0;
    showSuccessToastRef.current = !isStatementEmpty;
    if (statement?.length >= 6001) {
      return;
    }

    const formData = {
      introduction: statement,
    };
    updateUserProfile(formData, handleUpdateStatementSuccess);
  };

  const handleClickIconStatement = () => {
    setIsEditState(true);
  };

  const handleClickCancel = () => {
    setIsEditState(false);
    setStatement(defaultStatement);
  };

  const handleChangestatement = (e) => {
    const value = e.target.value;
    setStatement(value);
  };

  return (
    <Box className={classes.wrapBoxStatement}>
      <div className={classes.row}>
        {(isOwner || statement) && (
          <Text
            type="lg"
            fontWeightBold
          >
            {t('artist_statement')}
          </Text>
        )}
        {isOwner && (
          <>
            {!statement ? (
              <AddIcon
                className={classes.icon}
                onClick={handleClickIconStatement}
              />
            ) : (
              <PenEdit
                className={classes.icon}
                onClick={handleClickIconStatement}
                testid={TESTID.EDIT_ICON}
              />
            )}
          </>
        )}
      </div>

      <RenderStatement
        isEditState={isEditState}
        statement={statement}
        isOwner={isOwner}
        setIsEditState={setIsEditState}
        updateAccountStatement={handleUpdateAccountStatement}
        handleChangestatement={handleChangestatement}
        handleClickCancel={handleClickCancel}
      />
    </Box>
  );
};

export default BoxStatement;
