import React from 'react';

import useRole from 'hooks/useRole';

import StatusPriceRoleArtist from './components/StatusPriceOption/StatusPriceRoleArtist';
import StatusPriceRoleCollector from './components/StatusPriceOption/StatusPriceRoleCollector';

const StatusDisplayItem = ({ status }) => {
  const { isArtist, isCollectorOrGalleryOwner } = useRole();

  if (isArtist) {
    return <StatusPriceRoleArtist status={status?.key} />;
  }

  if (isCollectorOrGalleryOwner) {
    return <StatusPriceRoleCollector status={status?.key} />;
  }

  return null;
};

export default StatusDisplayItem;
