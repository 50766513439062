import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textFieldStyle: {
    '& .MuiInputBase-input': {
      padding: '12px 8px',
      backgroundColor: Colors.White,
    },
  },
  wrapIosSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  textPublicProfile: {
    fontSize: 14,
  },
  wrapFormYearInput: {
    width: '50%',
  },
  wrapFormDescriptionInput: {
    width: '100%',
  },
  wrapInput: {
    display: 'flex',
    marginTop: -10,
    gap: 20,
  },
}));

export default useStyles;
