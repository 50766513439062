import { Divider, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Colors from 'configs/Colors';

const NotificationHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between' }}
      >
        <Text fontWeightBold>{t('all_notifications')}</Text>
        <Text
          fontWeightBold
          color={Colors.Blue6}
        >
          {t('mark_all_as_read')}
        </Text>
      </Stack>

      <Divider sx={{ mt: 2 }} />
    </>
  );
};

export default NotificationHeader;
