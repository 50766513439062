import * as yup from 'yup';

export const passwordSchema = (t) =>
  yup.object().shape({
    oldPassword: yup.string().required(t('old_password_required')),
    password: yup
      .string()
      .min(8, t('password_min_length'))
      .matches(/[a-z]/, t('password_lowercase'))
      .matches(/[A-Z]/, t('password_uppercase'))
      .matches(/[0-9!@#$%]/, t('password_symbol_or_number'))
      .required(t('password_required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('passwords_must_match'))
      .required(t('confirm_password_required')),
  });
