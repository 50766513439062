import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CollectionActionBlock from 'commons/Collection/CollectionActionBlock';
import CollectionDetailBlock from 'commons/Collection/CollectionDetailBlock';
import API from 'configs/API';
import GridLayout from 'layouts/GridLayout';
import { collectionSchema } from 'pages/EditCollection/validates/collectionSchema';
import Routes from 'utils/Route';
import { axiosPatch } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const CollectionMainContent = ({ collection }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const collectionFormState = useForm({
    values: {
      title: collection?.title,
      description: collection?.description,
      isCollectionVisible: collection?.is_public,
      addedArtworks: collection?.artwork_ids,
    },
    mode: 'onChange',
    resolver: yupResolver(collectionSchema),
  });

  const { formState, setValue } = collectionFormState;

  const handleEditCollection = async (data) => {
    const formData = {
      title: data.title,
      description: data.description,
      owner: user.id,
      is_public: data.isCollectionVisible,
      artworks: data.addedArtworks,
    };

    const { success } = await axiosPatch(API.ARTWORK.COLLECTION_DETAIL(collection?.uuid), formData);
    if (success) {
      queryClient.invalidateQueries({
        queryKey: [API.ARTWORK.COLLECTION_DETAIL_FOR_EDIT(collection?.uuid)],
      });
      ToastTopHelper.success(t('collection_edited_successfully'));
      navigate(Routes.CollectionDetail(collection?.uuid));
    }
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Box>
          <GridLayout>
            <CollectionActionBlock
              title={t('edit_collection')}
              formState={formState}
              setValue={setValue}
              collection={collection}
            />
            <CollectionDetailBlock
              textButtonRight={t('edit_collection')}
              collectionFormState={collectionFormState}
              handleSubmitCollection={handleEditCollection}
            />
          </GridLayout>
        </Box>
      </Container>
    </div>
  );
};

export default CollectionMainContent;
