import { Box, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import OurService1 from 'images/our_service_1.png';
import OurService2 from 'images/our_service_2.png';
import OurService3 from 'images/our_service_3.png';

import useStyles from './styles';

const OurService = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Text className={classes.textProvideInnovative}>{t('gladius_art_provides_innovative')}</Text>
      <Grid
        container
        className={classes.gridOurService}
      >
        <Grid
          item
          xs={12}
          md={3.5}
          className={classes.relativeContainer}
        >
          <div className={classes.wrapOurService}>
            <img
              className={classes.ourService}
              src={OurService1}
            />
            <Text className={classes.centeredText}>{t('artists')}</Text>
          </div>
          <Text className={classes.subText}>{t('gladius_art_offers_range_tools')}</Text>
        </Grid>
        <Grid
          item
          xs={12}
          md={3.5}
          className={classes.relativeContainer}
        >
          <div className={classes.wrapOurService}>
            <img
              className={classes.ourService}
              src={OurService2}
            />
            <Text className={classes.centeredText}>{t('collectors')}</Text>
          </div>
          <Text className={classes.subText}>
            {t('an_art_data_management_platform_helps_collectors_organize')}
          </Text>
        </Grid>
        <Grid
          item
          xs={12}
          md={3.5}
          className={classes.relativeContainer}
        >
          <div className={classes.wrapOurService}>
            <img
              className={classes.ourService}
              src={OurService3}
            />
            <Text className={classes.centeredText}>{t('art_galleries')}</Text>
          </div>
          <Text className={classes.subText}>{t('gladius_art_provides_dynamic_platform')}</Text>
        </Grid>
      </Grid>

      <Box className={classes.backgroundJoinUs}>
        <Text className={classes.textJoinUs}>{t('join_us_at_gladius_art')}</Text>
        <div className={classes.buttonJoinUs}>{t('join_us_now')}</div>
      </Box>
    </div>
  );
};

export default OurService;
